<template>
    <NuxtLayout>
        <Sections>
            <SectionText
                :heading="errorTitle"
                :content="'<p>' + errorMessage + '</p>'"
            ></SectionText>
        </Sections>
    </NuxtLayout>
</template>

<script setup>
import {onMounted} from 'vue';
const {$config} = useNuxtApp();
const $route = useRoute();

const props = defineProps({
    error: Object,
});

const errorTitle = computed(() => {
    if (!props.error) return '500 Server Error';
    switch (props.error.statusCode) {
        case 404:
            return '404 Not Found';
        default:
            return '500 Server Error';
    }
});

const errorMessage = computed(() => {
    if (!props.error || !props.error.statusMessage) return 'Something went wrong, please contact support.';
    return props.error.statusMessage + '.';
});

useSeo().buildHead({
    title: errorTitle.value + ' | ' + $config.public.siteName,
    description: errorMessage.value,
    index: false,
});

onMounted(() => {
    const slug = $route.path;
    const statusCode = props?.error?.statusCode || 500;
    if (statusCode === 404) useEntityAction().postActionNotFound(slug);
});
</script>
