export const useStoreThreads = defineStore('StoreThreads', {
    state: () => ({
        threads: null,
        poller: null,
        paused: null,
        hasMore: null,
        timeout: null,
        error: null,
        filter: {
            paginate: {
                start: 0,
                limit: 35,
            },
            fields: {},
            order: [
                {field: 'thread_edited', asc: false},
            ],
            query: {
                value: '',
                fields: [
                    'thread_title',
                    'thread_last_message',
                ],
            },
        },
    }),
    actions: {
        stopThreadsPoller () {
            this.paused = true;
        },
        startThreadsPoller () {
            this.paused = false;
            if (!this.poller) {
                this.pollThreads();
            }
            this.getThreads();
        },
        pollThreads () {
            if (this.poller) return false;
            this.poller = setInterval(() => {
                this.getThreads();
            }, 5000);
        },
        async getThreads () {
            const storeAuth = useStoreAuth();
            if (!storeAuth.isLoggedIn || this.paused) return false;
            ({threads: this.threads, error: this.error, hasMore: this.hasMore} = await useQueryThreads().get(this.filter));
        },
        queryResults (search) {
            if (this.timeout) clearTimeout(this.timeout);
            if (this.hasMore) this.hasMore = null;
            this.timeout = setTimeout(() => {
                this.filter.query.value = search;
                this.getThreads();
            }, 500);
        },
        loadMore () {
            if (!this.hasMore) return false;
            this.filter.paginate.limit = this.filter.paginate.limit + 35;
            this.hasMore = false;
            this.getThreads();
        },
        readThread (thread) {
            this.setThreadRead(thread.id_thread);
        },
        reset () {
            this.paused = true;
            this.threads = null;
            if (this.timeout) clearTimeout(this.timeout);
            this.filter.paginate.limit = 35;
            this.hasMore = null;
            this.error = null;
        },
        setThreadRead (idThread){
            if (!this.threads) return;
            for (let i = 0; i < this.threads.length; i++) {
                if (this.threads[i].id_thread === idThread) {
                    let thread = this.threads[i];
                    thread.is_unread = false;
                    this.threads[i] = thread;
                }
            }
        },
    },
    getters: {
        searchQuery () {
            return this.filter.query.value;
        },
    },
});
