<template>
    <div class="article-cookies-audio">
        <p>
            Audio cookies enable music playlists embedded in the website to play, and may involve third party cookies
            which track information about you.
        </p>
        <ul>
            <li>
                <strong>Spotify Cookies</strong>
                - Spotify use multiple cookies to collect or receive information about you when you view an embedded
                Spotify playlist on our website.
                Spotify's privacy policy is available at:
                <a href="https://www.spotify.com/uk/legal/privacy-policy/">https://www.spotify.com/uk/legal/privacy-policy/</a>
                (cookies used for this purpose are: Multiple Spotify Cookies).
            </li>
            <li>
                <strong>Soundslice</strong>
                - Soundslice use multiple cookies to collect or receive information about you when you view an embedded
                Soundslice player on our website.
                Soundslice's privacy policy is available at: <a href="https://www.soundslice.com/terms/">https://www.soundslice.com/terms/</a>.
                (cookies used for this purpose are: Multiple Soundslice Cookies).
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleCookiesAudio',
};
</script>
