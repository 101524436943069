export default function () {

    async function post (params) {
        let userLesson = null, error = null;
        try {
            const {data} = await useApi('lessons').post('me/user-lessons', params);
            userLesson = data.user_lesson;
        } catch (e) {
            error = useApiError(e);
        }
        return {userLesson, error};
    }

    async function remove (idLesson) {
        let error = null;
        try {
            await useApi('lessons').delete('me/list-lessons/' + idLesson);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    return {post, remove};
}
