<template>
    <div class="article-answer-private-lessons">
        <p>
            Unfortunately, Andy does not offer private lessons at this time.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerPrivateLessons',
};
</script>
