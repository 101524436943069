export default function () {
    const $config = useRuntimeConfig().public;

    const cookie1 = useCookie('_ga');
    const cookie2 = useCookie('_ga', {path: '/', domain: 'localhost'});
    const cookie3 = useCookie('_ga', {path: '/', domain: $config.gaDomain});
    const cookie4 = useCookie($config.gaCookieId);
    const cookie5 = useCookie($config.gaCookieId, {path: '/', domain: 'localhost'});
    const cookie6 = useCookie($config.gaCookieId, {path: '/', domain: $config.gaDomain});

    function removeAll () {
        cookie1.value = null;
        cookie2.value = null;
        cookie3.value = null;
        cookie4.value = null;
        cookie5.value = null;
        cookie6.value = null;
    }

    return {removeAll};
};
