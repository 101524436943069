import type { RouterConfig } from '@nuxt/schema';
import redirectNamedRoutes from "./redirect-named-routes.js";

export default {
    // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
    routes: (scannedRoutes) => [
        ...scannedRoutes,
        ...redirectNamedRoutes,
    ],
} satisfies RouterConfig;
