<template>
    <div class="article-answer-paypal-accepted">
        <p>
            We can only accept card payments for the memberships at this time, not PayPal.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerPaypalAccepted',
};
</script>
