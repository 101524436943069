<template>
    <div class="article-answer-how-watch">
        <p>
            You can watch on any web browser on any device. The Andy Guitar app is available for Android users. You can
            download this from the Google Play Store.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerHowWatch',
};
</script>
