const controllers = {};

export default function () {

    const $config = useRuntimeConfig().public;

    // Provides signal for api to supersede previous requests with the latest
    function supersede (key) {
        if (!key) return undefined;
        if (import.meta.server) return undefined;
        if (controllers[key]) {
            if($config.stage === 'dev') console.log(`Superseding api call ${key}`);
            controllers[key].abort();
        }
        controllers[key] = new AbortController();
        return controllers[key].signal;
    }

    function remove (key) {
        if (!key) return;
        if (import.meta.server) return;
        if ($config.stage === 'dev') console.log(`Abort controller ${key} removed.`);
        controllers[key] = undefined;
    }

    return {supersede, remove};
}
