export default function () {

    const entitySession = useEntitySession();

    async function get (filter) {
        let sessions = null, error = null;
        try {
            const {data} = await useApi('auth').get('me/sessions', filter);
            sessions = data.sessions.map((session) => {
                return entitySession.build(session);
            });
        } catch (e) {
            error = useApiError(e);
        }
        return {sessions, error};
    }

    return {get};
}
