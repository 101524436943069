export const useStoreUserLessons = defineStore('StoreUserLessons', {
    state: () => ({
        userLessons: null,
        userLesson: null,
        disabled: null,
        error: null,
        filter: {
            fields: {
                'user_lesson_type': 'list'
            },
            order: [
                {field: 'id_user_lesson', asc: false}
            ],
            paginate: {
                auto: false,
            }
        },
        hasMore: null,
        toggleUserListLessonError: null,
    }),
    actions: {
        async getUserLessons () {
            this.error = null;
            ({userLessons: this.userLessons, hasMore: this.hasMore, error: this.error} = await useQueryUserLessons().get(this.filter));
        },
        loadMore: function (number) {
            if (!this.hasMore) return false;
            this.filter.paginate.limit = this.filter.paginate.limit + (number || 30);
            return this.getUserLessons();
        },
        async postUserListLesson (idLesson) {
            this.disabled = true;
            this.toggleUserListLessonError = null;
            const params = {
                user_lesson_type: 'list',
                id_lesson: idLesson,
            };
            ({error: this.toggleUserListLessonError} = await useEntityUserLesson().post(params));
            await this.afterToggleListLesson();
            this.disabled = false;
        },
        async deleteUserListLesson (idLesson) {
            this.disabled = true;
            this.toggleUserListLessonError = null;
            ({error: this.toggleUserListLessonError} = await useEntityUserLesson().remove(idLesson));
            await this.afterToggleListLesson();
            this.disabled = false;
        },
        afterToggleListLesson () {
            const lessonSlug = useStoreLesson().lesson?.lesson_slug;
            const courseSlug = useStoreLesson().lesson?.lesson_course?.course_slug;
            const pathwaySlug = useStoreLesson().pathway?.tag_slug || undefined;
            return useStoreLesson().getLesson({lessonSlug, courseSlug, pathwaySlug});
        },
        reset: function () {
            this.userLessons = null;
            this.disabled = null;
            this.error = null;
            this.toggleUserListLessonError = null;
        },
    },
});
