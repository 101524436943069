<template>
    <div class="article-welcome-trial-unavailable">
        <p>Welcome to your account:</p>
        <ul>
            <li>Continue where you left off</li>
            <li>Add videos to your list</li>
            <li>Find fresh new songs and lessons</li>
            <li>Browse free resources</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleWelcomeTrialUnavailable',
};
</script>
