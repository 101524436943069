export const useStoreLogin = defineStore('StoreLogin', {
    state: () => ({
        initialised: false,
        email: null,
        remember: true,
        emailed: false,
        passcode: null,
        loading: true,
        error: null,
    }),
    actions: {
        initLogin (passcode) {
            this.email = useCookieEmail().get();
            this.remember = true;
            this.emailed = false;
            this.passcode = passcode || null;
            this.loading = false;
            this.initialised = true;
            if (this.email && this.passcode) {
                this.validatePasscode();
            }
        },
        changeEmail ({email, remember}) {
            this.remember = remember;
            this.email = email;
            if (this.remember) {
                useCookieEmail().set(this.email);
            } else {
                useCookieEmail().remove();
            }
            if (this.passcode) {
                this.validatePasscode();
            } else {
                this.emailPasscode();
            }
        },
        clearEmail () {
            this.email = null;
            this.error = null;
            useCookieEmail().remove();
        },
        async emailPasscode () {
            if (!this.email) return false;
            this.loading = true;
            this.error = null;
            ({error: this.error} = await useEntityPasscode().post(this.email));
            this.loading = false;
            if (!this.error) {
                this.emailed = true;
            }
        },
        async validatePasscode () {
            if (!this.email || !this.passcode) return false;
            this.loading = true;
            this.error = null;
            await useStoreAuth().clearCurrentUser();
            const {jwt, error} = await useEntityPasscode().put({email: this.email, passcode: this.passcode});
            if (jwt) {
                await useStoreAuth().setCurrentUser(jwt);
                useStoreHelpdesk().initHelpdesk();
                const {$returnRoute} = useNuxtApp();
                return $returnRoute.use();
            }
            this.error = error;
            this.loading = false;
        },
        changePasscode (passcode) {
            this.passcode = passcode;
        },
        restartLogin () {
            this.initLogin();
            this.error = null;
            const $router = useRouter();
            $router.replace('/login').catch(() => {});
        },
    },
    getters: {
        showRestartLogin () {
            return [
                'That link or code no longer works because you have already used it, please try again.',
                'That link or code no longer works because it is too old, please try again.',
                'Login failed. Please check your email address is correct, and try again.',
            ].includes(this.error);
        },
    },
});
