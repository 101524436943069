<template>
    <div class="article-live-stream-fallback-description">
        <p>
            Join me for another live session where we work directly on your playing. I aim to give you the
            feedback needed for you to move forward and get inspired!
        </p>
        <p>
            Don't forget you can also request a tutorial for a song you want to learn and I'll teach it during the session live!
            Each live stream is automatically available as a replay so you can catch up anytime and never miss a beat!
        </p>
    </div>
</template>

<script>
export default {
    name: "ArticleLiveStreamFallbackDescription"
};
</script>
