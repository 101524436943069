<template>
    <div class="article-answer-app-sync">
        <p>
            Simply use the same email and login details for the app and the website to sync your accounts. Your
            <NuxtLink to="/membership">membership</NuxtLink> will be available across platforms.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerAppSync',
};
</script>
