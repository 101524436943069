<template>
    <div class="article-answer-why-andy">
        <p>
            Andy Guitar is for anyone looking to make real progress on guitar or ukulele. Andy caters for absolute
            beginners and continues to guide you through intermediate, improver and advanced levels.
        </p>
        <p>
            Andy aims to give you the feel of a one to one lesson, but from the comfort of your own home and
            with the advantage of being able to pause, rewind and repeat as many times as you want!
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerWhyAndy',
};
</script>
