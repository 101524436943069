export default function () {

    function build (entity) {
        const UtilityDates = useUtilityDates();

        if (!entity) throw Error('No supplied entity.');
        return {
            ...entity,
            lesson_route: lessonRoute(entity),
            lesson_excerpt_fallback: lessonExcerptFallback(entity),
            lesson_video_thumbnail_url: lessonVideoThumbnailUrl(entity),
            lesson_video_sources_secure: lessonVideoSourcesSecure(entity),
            lesson_video_content_url: lessonVideoContentUrl(entity),
            lesson_video_embed_url: lessonVideoEmbedUrl(entity),
            lesson_image_url: lessonImageUrl(entity),
            lesson_thumb_url: lessonThumbUrl(entity),
            lesson_time_from_now: UtilityDates.printDateTimeAgo(entity.lesson_created),
            lesson_subtitle: lessonSubtitle(entity),
        };
    }

    function lessonSubtitle(entity) {
        if (entity.lesson_category === 'Lesson' && entity.course_title) {
            return entity.course_title;
        } else if (entity.lesson_category === 'Lesson' && !entity.course_title) {
            return 'Lesson';
        } else if (entity.lesson_category === 'Live Replay') {
            return 'Live Replay';
        } else if (entity.lesson_artist) {
            return 'Song by ' + entity.lesson_artist;
        } else if (entity.lesson_type) {
            return entity.lesson_type;
        }else if (entity.lesson_category === 'Song' && !entity.lesson_artist) {
            return 'Song';
        } else  {
            return null;
        }
    }

    async function get (lessonSlug, courseSlug, pathwaySlug) {
        const ApiLessons = useApi('lessons');
        const QueryPathways = useQueryPathways();
        let lesson = null, pathway = null, pathwayCourses = null, error = null, is404 = false;
        let endpoint = courseSlug ? 'courses/slug/' + courseSlug + '/lessons/slug/' + lessonSlug : 'lessons/slug/' + lessonSlug;
        try {
            let promises = [
                ApiLessons.get(endpoint),
            ];
            if (pathwaySlug) {
                promises.push(QueryPathways.get({fields: {pathway_slug: pathwaySlug}}));
            }
            let results = await Promise.all(promises);
            lesson = build(results[0].data.lesson);
            if (pathwaySlug) {
                pathway = results[1].pathways[0];
                pathwayCourses = pathway?.pathway_courses;
            }
            let defaultPathwaySlug = lesson?.lesson_course?.default_pathway_slug;
            if (!pathwaySlug && defaultPathwaySlug) {
                const {pathways} = await QueryPathways.get({fields: {pathway_slug: defaultPathwaySlug}});
                pathway = pathways[0];
                pathwayCourses = pathway?.pathway_courses;
            }
        } catch (e) {
            is404 = e.response?.status === 404;
            error = useApiError(e);
        }
        return {lesson, pathway, pathwayCourses, error, is404};
    }

    const UtilityImages = useUtilityImages();
    const UtilityFunctions = useUtilityFunctions();

    function lessonRoute (entity) {
        if (!entity.lesson_slug) return undefined;
        return {
            name: 'videos-lessonSlug',
            params: {
                lessonSlug: entity.lesson_slug,
            },
        };
    }

    function lessonExcerptFallback (entity) {
        let lessonTextContent = (entity?.lesson_description_embed_items?.content_blocks || []).join(' ');
        return UtilityFunctions.shortenString(220, UtilityFunctions.removeHtmlTags(lessonTextContent));
    }

    function lessonVideoThumbnailUrl (entity) {
        return UtilityImages.getImageUrl(entity.lesson_video_thumbnail, 'images-lesson', 'lesson-image.gif');
    }

    function lessonVideoSourcesSecure (entity) {
        if (!entity.lesson_video_sources) return undefined;
        return {
            poster: entity?.lesson_video_sources.find(src => src.ext === 'mp4')?.poster,
            webm: entity?.lesson_video_sources.find(src => src.ext === 'webm')?.url,
            mp4: entity?.lesson_video_sources.find(src => src.ext === 'mp4')?.url,
            url: entity?.lesson_video_sources.find(src => src.ext === 'mp4')?.url,
        };
    }

    function lessonVideoContentUrl (entity) {
        if (!entity.lesson_video_sources) return undefined;
        return entity.lesson_video_sources.find(src => src.ext === 'mp4')?.url;
    }

    function lessonVideoEmbedUrl (entity) {
        if (!entity.lesson_video_sources) return undefined;
        return entity.lesson_video_sources.find(src => src.ext === 'youtube')?.url;
    }

    function lessonImageUrl (entity) {
        return UtilityImages.getImageUrl(entity.lesson_image, 'images-lesson', 'lesson-image.gif');
    }

    function lessonThumbUrl (entity) {
        return UtilityImages.getImageUrl(entity.lesson_thumb, 'images-lesson', 'lesson-thumb.gif');
    }

    return {build, get};
}
