import type {CookieRef} from '#app';

export const useStoreCookies = defineStore('StoreCookies', () => {

    const $router = useRouter();
    const {initialize, disableAnalytics} = useGtag();
    const config = useRuntimeConfig().public;

    const cookies = {
        asked: useCookieAsked(),
        video: useCookieVideo(),
        audio: useCookieAudio(),
        marketing: useCookieMarketing(),
        payment: useCookiePayment(),
        analytic: useCookieAnalytic(),
    } as Cookies;

    const cookieValues = computed(() => {
        return {
            asked: !!cookies.asked.value,
            video: !!cookies.video.value,
            audio: !!cookies.audio.value,
            marketing: !!cookies.marketing.value,
            payment: !!cookies.payment.value,
            analytic: !!cookies.analytic.value,
        };
    });

    function initCookies () {
        if (!!cookies.analytic.value) {
            initialize(config.gaTrackingId);
        }
        if (!!cookies.marketing.value) {
            initialize(config.awTrackingId);
        }
    }

    function setCookie (key: keyof Cookies) {
        const cookie = cookies[key];
        const d = new Date();
        cookie.value = String(d.getTime());
        if (key === 'analytic') {
            initialize(config.gaTrackingId);
        }
        if (key === 'marketing') {
            initialize(config.awTrackingId);
        }
    }

    function removeCookie(key: keyof Cookies) {
        const cookie = cookies[key];
        cookie.value = null;
        if (key === 'analytic' || key === 'marketing') {
            disableAnalytics();
            useCookieGtag().removeAll();
        }
    }

    function enableCookie (key: keyof Cookies) {
        setCookie(key);
    }

    function toggleCookies (key: keyof Cookies) {
        !!cookies[key].value ? removeCookie(key) : setCookie(key);
    }

    function acceptDefaultCookies () {
        setCookie('analytic');
        setCookie('video');
        setCookie('audio');
        setCookie('marketing');
        setCookie('payment');
        setCookie('asked');
    }

    function customiseCookieSettings () {
        setCookie('asked');
        $router.push({name: 'cookies'}).then(() => {}).catch(() => {});
    }

    const cookieNotice = computed(() => {
        let result = 'We use cookies to ';
        result += 'embed videos, ';
        result += 'embed music players, ';
        result += 'improve our website, ';
        result += 'improve the relevancy of advertising campaigns you receive, ';
        result += 'process payments, ';
        result += 'and ';
        result += 'enable core functionality.';
        return result;
    });

    return {cookies: cookieValues, initCookies, enableCookie, toggleCookies, acceptDefaultCookies, customiseCookieSettings, cookieNotice};
});

interface Cookies {
    asked: CookieRef<string | null | undefined>
    video: CookieRef<string | null | undefined>
    audio: CookieRef<string | null | undefined>
    marketing: CookieRef<string | null | undefined>
    payment: CookieRef<string | null | undefined>
    analytic: CookieRef<string | null | undefined>
}
