<template>
    <div class="article-welcome-trial-available">
        <p>Unlock your free trial of my AAA membership now:</p>
        <ul>
            <li>Get full access to all courses and lessons</li>
            <li>Weekly Live Q&A with Andy</li>
            <li>Discord community access</li>
            <li>Interactive TAB</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleWelcomeTrialAvailable',
};
</script>
