export default function () {

    function build(entity) {
        if (!entity) throw Error('No supplied entity.');
        return {
            ...entity,
            course_route: courseRoute(entity),
            course_image_url: courseImageUrl(entity),
            course_thumb_url: courseThumbUrl(entity),
            course_jumbo_image_url: courseJumboImageUrl(entity),
            course_lessons: courseLessons(entity),
        };
    }

    async function get (courseSlug) {
        let course = null, error = null, is404 = false;
        try {
            const {data} = await useApi('lessons').get('courses/slug/' + courseSlug + '/extended');
            course = build(data.course);
        } catch (e) {
            is404 = (e.response?.status === 404);
            error = useApiError(e);
        }
        return {course, error, is404};
    }

    const UtilityImages = useUtilityImages();
    const EntityLesson = useEntityLesson();

    function courseRoute (entity) {
        if (!entity.course_slug) return undefined;
        return {
            name: 'courses-courseSlug',
            params: {
                courseSlug: entity.course_slug,
            },
        };
    }

    function courseImageUrl (entity) {
        return UtilityImages.getImageUrl(entity.course_image, 'images-course', 'course-image.gif');
    }

    function courseThumbUrl (entity) {
        return UtilityImages.getImageUrl(entity.course_thumb, 'images-course', 'course-thumb.gif');
    }

    function courseJumboImageUrl (entity) {
        if (!entity.course_jumbo_image) return undefined;
        return UtilityImages.getImageUrl(entity.course_jumbo_image, 'images-course');
    }

    function courseLessons (entity) {
        if (!entity.course_lessons) return [];
        return entity.course_lessons.map((lesson) => {
            return EntityLesson.build(lesson);
        });
    }

    return {build, get};
}
