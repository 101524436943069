<template>
    <svg
        class="icon-live" :class="iconClasses"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
    >
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"/>
    </svg>
</template>

<script>
export default {
    name: 'IconLive',
    props: {
        size: {
            required: false,
            type: Number,
            default: 6,
        },
        marginAuto: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    computed: {
        iconClasses () {
            const cls = {};
            cls[`h-${this.size} w-${this.size}`] = true;
            cls['m-auto'] = !!this.marginAuto;
            return cls;
        },
    },
};
</script>
