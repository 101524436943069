export default function () {

    const entityMessage = useEntityMessage();

    async function get (idThread, filter, token) {
        let messages = null, error = null, hasMore = false;
        let endpoint = token ? 'threads/' + idThread + '/' + token + '/messages' : 'threads/' + idThread + '/messages';
        try {
            const {data} = await useApi('messages').get(endpoint, filter);
            messages = data.messages.slice().reverse().map((message) => {
                return entityMessage.build(message);
            });
            hasMore = (messages.length === filter?.paginate?.limit);
        } catch (e) {
            error = useApiError(e);
        }
        return {messages, error, hasMore};
    }

    return {get};
}
