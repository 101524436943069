<template>
    <div class="article-cookies-necessary">
        <p>
            Necessary cookies enable core functionality and can only be disabled by changing your browser
            preferences.
        </p>
        <ul>
            <li>
                <strong>Authentication</strong> - we use cookies to identify you when you visit our website
                and as you navigate our website (cookies used for this purpose are: email).
            </li>
            <li>
                <strong>Status</strong> - we use cookies to help us to determine if you are logged into our
                website (cookies used for this purpose are: token).
            </li>
            <li>
                <strong>Security</strong> - we use cookies as an element of the security measures used to
                protect user accounts, including preventing fraudulent use of
                login credentials, and to protect our website and services generally (cookies used for this
                purpose are: correlation).
            </li>
            <li>
                <strong>Cookie Consent</strong> - we use cookies to store your preferences in relation to
                the use of cookies more generally (cookies used for this purpose are: {{ cookiesUsed }}).
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleCookiesNecessary',
    computed: {
        cookiesUsed () {
            let cookies = 'cookies_asked';
            cookies += ', cookies_video';
            cookies += ', cookies_audio';
            cookies += ', cookies_analytic';
            cookies += ', cookies_marketing';
            cookies += ', cookies_payment';
            return cookies;
        },
    },
};
</script>
