<template>
    <div class="article-answer-how-cancel">
        <p>
            View and manage your membership <NuxtLink to="/me">here</NuxtLink>, or send us a message using the button below. Andy offers a
            60-day money back guarantee if you are not completely satisfied.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerHowCancel',
};
</script>
