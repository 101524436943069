<template>
    <div class="article-answer-app-cast">
        <p>
            Yes! For the best experience, please use Chromecast to cast course videos or song tutorials to your smart TV.
            You can do this from the website or app.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerAppCast',
};
</script>
