export default function () {

    async function get () {
        let count = null, error = null;
        try {
            const {data} = await useApi('messages').get('threads/unread/count');
            count = data.count;
        } catch (e) {
            error = useApiError(e);
        }
        return {count, error};
    }

    return {get};
}
