export default function () {

    async function get (subscriptionProductSlug) {
        let product = null, error = null;
        try {
            const {data} = await useApi('finance').get('subscription-products/slug/' + subscriptionProductSlug);
            product = data.subscription_product;
        } catch (e) {
            error = useApiError(e);
        }
        return {product, error};
    }

    return {get};
}
