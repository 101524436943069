<template>
    <div class="article-cookies-analytical">
        <p>Analytical cookies help us to improve our website by collecting and reporting information on its usage.</p>
        <ul>
            <li>
                <strong>Google Analytics</strong>
                - we use Google Analytics to analyse the use of our website. Google Analytics gathers information about
                website use by means of cookies.
                The information gathered relating to our website is used to create reports about the use of our website.
                You can opt out of being tracked by Google Analytics across all websites you visit here
                - <a rel="nofollow" target="_blank" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
                (cookies used for this purpose are: _ga, _gid, _gat, __utma, __utmt, __utmb, __utmc, __utmz, __utmv).
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleCookiesAnalytical',
};
</script>
