export default function () {

    function build (entity) {
        if (!entity) throw Error('No supplied entity.');
        return {
            ...entity,
            subscription_route: subscriptionRoute(entity),
            subscription_status_readable: subscriptionStatusReadable(entity),
        };
    }

    async function get (idSubscription) {
        let subscription = null, error = null;
        try {
            const {data} = await useApi('finance').get('me/subscriptions/' + idSubscription);
            subscription = data.subscription;
        } catch (e) {
            error = useApiError(e);
        }
        return {subscription, error};
    }

    async function post (params) {
        let subscription = null, error = null;
        try {
            const {data} = await useApi('finance').post('me/subscriptions', params);
            subscription = data.subscription;
        } catch (e) {
            error = useApiError(e);
        }
        return {subscription, error};
    }

    async function cancel (idSubscription) {
        let subscription = null, error = null;
        try {
            const {data} = await useApi('finance').put('me/subscriptions/' + idSubscription + '/status/cancelled');
            subscription = data.subscription;
        } catch (e) {
            error = useApiError(e);
        }
        return {subscription, error};
    }

    function subscriptionRoute (entity) {
        if (!entity.id_subscription) return undefined;
        return {
            name: 'me-subscriptions-idSubscription',
            params: {
                idSubscription: entity.id_subscription,
            },
        };
    }

    function subscriptionStatusReadable (entity) {
        switch (entity.subscription_status) {
            case 'draft':
                return 'Draft Subscription';
            case 'created':
                return 'Awaiting First Payment';
            case 'initialised':
                return 'First Payment Processing';
            case 'active':
                return 'Active';
            case 'incomplete':
                return 'First Payment Failed';
            case 'failed':
                return 'Payment Failed';
            case 'cancelled':
                return 'Cancelled';
            default:
                return 'Unknown';
        }
    }

    return {build, get, post, cancel};
}
