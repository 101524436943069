function redirectLesson (from) {
    return `/videos/${from.params.lessonSlug}`;
}

function redirectVideosSearch(from) {
    return `/videos/?tags=${from.params.tagSlug}`;
}

function redirectCourse(from) {
    return `/courses/${from.params.courseSlug}`;
}

function redirectSubscription(from) {
    return `/me/subscriptions/${from.params.idSubscription}`;
}

export default [
    // Old subscription confirmation email
    {path: '/guest/subscriptions/:idSubscription', redirect: redirectSubscription},

    // Old lesson routes
    {path: '/courses/:courseSlug/lessons/:lessonSlug',redirect: redirectLesson},
    {path: '/lessons/:lessonSlug',redirect: redirectLesson},
    {path: '/lessons/:lessonSlug/featured-products',redirect: redirectLesson},
    {path: '/lessons/:lessonSlug/related-lessons',redirect: redirectLesson},
    {path: '/online-lessons/:lessonSlug',redirect: redirectLesson},
    {path: '/online-lessons/bookmarks/:bookmarkSlug/lessons/:lessonSlug',redirect: redirectLesson},
    {path: '/online-lessons/courses/:courseSlug/lessons/:lessonSlug',redirect: redirectLesson},
    {path: '/online-lessons/lessons-search/:lessonSlug',redirect: redirectLesson},
    {path: '/online-lessons/lessons/:lessonSlug',redirect: redirectLesson},
    {path: '/online-lessons/meta/:metaTagSlug/lessons/:lessonSlug',redirect: redirectLesson},
    {path: '/online-lessons/tags/:tagSlug/lessons/:lessonSlug',redirect: redirectLesson},
    {path: '/songs/:lessonSlug',redirect: redirectLesson},

    // Old browse-by-tag routes
    {path: '/lessons/meta/:tagSlug', redirect: redirectVideosSearch},
    {path: '/lessons/tags/:tagSlug', redirect: redirectVideosSearch},
    {path: '/online-lessons/meta/:tagSlug', redirect: redirectVideosSearch},
    {path: '/online-lessons/tag/:tagSlug', redirect: redirectVideosSearch},
    {path: '/online-lessons/tags/:tagSlug', redirect: redirectVideosSearch},
    {path: '/online-lessons/type/:tagSlug', redirect: redirectVideosSearch},

    // Old course routes
    {path: '/online-lessons/:courseSlug/:moduleSlug', redirect: redirectCourse},
    {path: '/online-lessons/course/:courseSlug', redirect: redirectCourse},
    {path: '/online-lessons/courses/:courseSlug', redirect: redirectCourse},
    {path: '/online-lessons/courses/:courseSlug/:moduleSlug', redirect: redirectCourse},
    {path: '/online-lessons/courses/:courseSlug/lessons', redirect: redirectCourse},
    {path: '/online-lessons/courses/:courseSlug/modules/', redirect: redirectCourse},
    {path: '/online-lessons/courses/:courseSlug/modules/:moduleSlug', redirect: redirectCourse},
];
