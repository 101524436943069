import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

export default function () {
    const UtilityDates = {};

    UtilityDates.formats = {
        'longDate': 'MMM Do, YYYY',
        'shortDate': 'DD/MM/YYYY',
        'tinyDate': 'DD/MM/YY',
        'dayDate': 'dddd MMM D, YYYY',
        'shortTime': 'h:mm a'
    };

    UtilityDates.printDateTime = function (value, format) {
        return value ? dayjs(value).format(UtilityDates.formats[format]) : value;
    };

    UtilityDates.printDateTimeAgo = function (value, suffix) {
        return value ? dayjs(value).fromNow(suffix) : null;
    };

    return UtilityDates;
}
