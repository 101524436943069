import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/codebuild/output/src2093463017/src/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src2093463017/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/codebuild/output/src2093463017/src/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/codebuild/output/src2093463017/src/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import _01_error_lv8lvdg4Aa from "/codebuild/output/src2093463017/src/nuxt/plugins/01.error.js";
import _02_api_MCYDbIMen5 from "/codebuild/output/src2093463017/src/nuxt/plugins/02.api.js";
import _04_features_nQ3rNSCail from "/codebuild/output/src2093463017/src/nuxt/plugins/04.features.js";
import _06_return_4KyZ9mJSYF from "/codebuild/output/src2093463017/src/nuxt/plugins/06.return.js";
import _07_routerUtilities_xaTRM33OIe from "/codebuild/output/src2093463017/src/nuxt/plugins/07.routerUtilities.js";
import _12_videoUtilities_AozuOzSEDc from "/codebuild/output/src2093463017/src/nuxt/plugins/12.videoUtilities.js";
import _13_htmlUtilities_zjlOeI5dIi from "/codebuild/output/src2093463017/src/nuxt/plugins/13.htmlUtilities.js";
import _14_youtube_V2J9HIOOwb from "/codebuild/output/src2093463017/src/nuxt/plugins/14.youtube.js";
import _18_customFetch_Y8rMTY8DmA from "/codebuild/output/src2093463017/src/nuxt/plugins/18.customFetch.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  _01_error_lv8lvdg4Aa,
  _02_api_MCYDbIMen5,
  _04_features_nQ3rNSCail,
  _06_return_4KyZ9mJSYF,
  _07_routerUtilities_xaTRM33OIe,
  _12_videoUtilities_AozuOzSEDc,
  _13_htmlUtilities_zjlOeI5dIi,
  _14_youtube_V2J9HIOOwb,
  _18_customFetch_Y8rMTY8DmA
]