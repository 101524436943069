export const useStoreViewed = defineStore('StoreViewed', {
    state: () => ({
        viewed: null,
        error: null,
    }),
    actions: {
        async getViews () {
            this.error = null;
            ({viewed: this.viewed, error: this.error} = await useQueryViewed().get());
        },
        reset () {
            this.error = null;
        },
    },
});
