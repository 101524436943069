export default defineNuxtPlugin(nuxt => {
    const htmlUtilities = {};

    htmlUtilities.replaceButtonTags = function (html) {
        const regexp = /<button href=/g;
        const replacement = '<v-btn href=';
        let outputString = html.replace(regexp, replacement);
        outputString = outputString.replace(/<\/button>/g, '</v-btn>');
        return outputString;
    };

    htmlUtilities.generateYouTubeEmbed = function ({videoId}) {
        return `<embed-youtube idYoutube="${videoId}"></embed-youtube>`;
    };

    htmlUtilities.generateSpotifyPlaylistEmbed = function ({idPlaylist}) {
        return `<embed-spotify-playlist idPlaylist="${idPlaylist}"></embed-spotify-playlist>`;
    };

    htmlUtilities.generateSpotifyAlbumEmbed = function ({idAlbum}) {
        return `<embed-spotify-album idAlbum="${idAlbum}"></embed-spotify-album>`;
    };

    htmlUtilities.generateSoundsliceEmbed = function ({idSoundslice, type}) {
        return `<embed-soundslice idSoundslice="${idSoundslice}" type="${type}" ></embed-soundslice>`;
    };

    htmlUtilities.generateCloudfrontVideoEmbed = function ({videoUrl}) {
        return `
            <video-general-cloudfront src="${videoUrl}"/>
        `;
    };

    nuxt.provide('htmlUtilities', htmlUtilities);
});
