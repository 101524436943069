<template>
    <div class="article-donations">
        <ContentSubheading heading="Why Donate?" headingComponent="h2" marginBottom="mb-4"></ContentSubheading>
        <p>
            Like my free videos? Want to say thank you and support myself and the small team that make it all
            possible? Use this page to donate directly to Andy! Please donate if you can afford to and support more new better quality videos now and in the future!
        </p>
        <p>
            I receive emails every day from people who benefit from my free lessons on YouTube and on this website, with
            many saying they would like to support the lessons and website by donating. It really means a lot to me that
            people are willing to contribute to keep this site going, making improvements and keeping as much of it as
            possible free!
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleDonations',
};
</script>
