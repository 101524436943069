<template>
    <div class="article-answer-change-email">
        <p>
            To change your email, sign in and then go to your
            <NuxtLink to="/me">profile page</NuxtLink>.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerChangeEmail',
};
</script>
