export default function () {

    const EntityCourse = useEntityCourse();

    async function get () {
        let result = null, error = null;
        try {
            const {data} = await useApi('onboard').get('continue');
            result = data.continue;

            if (result.featured_course) {
                result.featured_course = EntityCourse.build(result.featured_course);
            }

        } catch (e) {
            error = useApiError(e);
        }
        return {result, error};
    }

    return {get};
}
