export default defineNuxtPlugin(nuxt => {
    const features = {};
    const storeFeatures = useStoreFeatures();

    features.featuresLinkInFooter = function () {
        return storeFeatures.getFeatureValue('featuresLinkInFooter');
    };

    features.optimizeImages = function () {
        return storeFeatures.getFeatureValue('optimizeImages');
    };

    // Available as $features
    nuxt.provide('features', features);
});
