export default function () {
    const UtilityFunctions = {};

    UtilityFunctions.ucWords = function (string) {
        string = string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
        });
        return string;
    };

    UtilityFunctions.ucWordsTidy = function (string) {
        return UtilityFunctions.ucWords(UtilityFunctions.spaceCommas(string));
    };

    UtilityFunctions.capitalizeFirstLetter = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    UtilityFunctions.spaceCommas = function (string) {
        return string.replace(/,/g, ', ');
    };

    UtilityFunctions.formatCurrency = function (number) {
        return new Intl.NumberFormat('en-gb', {style: 'currency', currency: 'GBP'}).format(number);
    };

    UtilityFunctions.cleanCurrency = function (string) {
        string = string.replace('£', '');
        string = string.replace('p', '');
        string = string.replace(',', '');
        return string;
    };

    UtilityFunctions.isString = function (myVar) {
        return (typeof myVar === 'string' || myVar instanceof String);
    };

    UtilityFunctions.isDateInPast = function (date) {
        return (new Date(date) <= new Date());
    };

    UtilityFunctions.isDateInFuture = function (date) {
        return (new Date(date) >= new Date());
    };

    UtilityFunctions.numberToFixed = function (number, dp) {
        return parseFloat(number).toFixed(dp);
    };

    /**
     * Utility function to return a count of all keys in an array, sorted by key
     *
     * @example
     * countGroupBy(['foo', 'foo', 'bar', 'buzz', 'foo', 'bar']);
     * returns: [{ key: 'bar', count: 2 }, { key: 'buzz', count: 1 }, { key: 'foo', count: 3 }]
     *
     * @param inputArray {array<string|number|boolean>}
     * @returns {array}
     */
    UtilityFunctions.countGroupBy = function (inputArray) {
        let sortedArray = inputArray.sort();
        let items = [];
        let lastItem;
        for (let i = 0; i < sortedArray.length; i++) {
            if (items.length) lastItem = items[items.length - 1].key;
            let thisItem = sortedArray[i];
            if (thisItem !== lastItem) {
                // This is an item with a new key, add it to the items array
                items.push({
                    key: thisItem,
                    count: 1
                });
            } else {
                // Increase count
                items[items.length - 1].count = items[items.length - 1].count + 1;
            }
        }
        return items;
    };

    /**
     * Utility function to return display size category
     * @param width {number} - Pixel width of the display
     * @param prependNumber {boolean} - Prepends category number e.g. '2 -  Small' instead of just 'Small'
     * @returns {string}
     */
    UtilityFunctions.displayCategory = function (width, prependNumber) {
        if (!width) return '0 - Unknown';
        if (width < 600) return prependNumber ? '1 - Extra Small' : 'Extra Small';
        if (width < 960) return prependNumber ? '2 - Small' : 'Small';
        if (width < 1264) return prependNumber ? '3 - Medium' : 'Medium';
        if (width < 1904) return prependNumber ? '4 - Large' : 'Large';
        if (width >= 1904) return prependNumber ? '5 - Extra Large' : 'Extra Large';
    };

    UtilityFunctions.removeHtmlTags = function (html) {
        return html.replace(/<[^>]*>?/gm, '');
    };

    UtilityFunctions.shortenString = function (length, str) {
        if (typeof str !== 'string' || typeof length !== 'number') {
            return '';
        }
        const trimmedStr = str.trim();
        if (trimmedStr.length <= length) {
            return trimmedStr;
        }
        return `${trimmedStr.substring(0, length)}...`;
    };

    UtilityFunctions.cloneObject = function (object) {
        return JSON.parse(JSON.stringify(object));
    };

    UtilityFunctions.delay = function (t, val) {
        console.log('Delaying...', t);
        return new Promise(resolve => setTimeout(resolve, t, val));
    };

    return UtilityFunctions;
}
