<template>
    <div class="layout-contained container mx-auto" :class="padding">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'LayoutContained',
    props: {
        padding: {
            required: false,
            type: String,
            default: 'px-6 md:px-8 lg:px-10',
        },
    },
};
</script>
