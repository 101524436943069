import validate from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45slash_45global from "/codebuild/output/src2093463017/src/nuxt/middleware/01.slash.global.ts";
import _02_45redirects_45global from "/codebuild/output/src2093463017/src/nuxt/middleware/02.redirects.global.js";
import _03_45shopify_45global from "/codebuild/output/src2093463017/src/nuxt/middleware/03.shopify.global.js";
import _04_45init_45global from "/codebuild/output/src2093463017/src/nuxt/middleware/04.init.global.js";
import _05_45return_45global from "/codebuild/output/src2093463017/src/nuxt/middleware/05.return.global.js";
import _06_45analytics_45global from "/codebuild/output/src2093463017/src/nuxt/middleware/06.analytics.global.js";
import manifest_45route_45rule from "/codebuild/output/src2093463017/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45slash_45global,
  _02_45redirects_45global,
  _03_45shopify_45global,
  _04_45init_45global,
  _05_45return_45global,
  _06_45analytics_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}