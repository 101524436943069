<template>
    <div class="article-answer-forgot-password">
        <p>
            Our website no longer uses passwords, each time you need to login we'll email you a one-time passcode.
        </p>
        <p>
            Simply hit the login button, then input your email and we'll send you a code.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerForgotPassword',
};
</script>
