export const useStoreContinue = defineStore('StoreContinue', {
    state: () => ({
        continue: null,
        error: null,
    }),
    actions: {
        async getContinue () {
            this.error = null;
            ({result: this.continue, error: this.error} = await useEntityContinue().get());
        },
        reset () {
            this.error = null;
        },
    },
    getters: {
        featuredCourse () {
            return this.continue?.featured_course;
        },
        featuredCourseProgress () {
            if (!this.featuredCourse) return null;
            if (!this.featuredCourse.percent_complete) return 0;
            return (this.featuredCourse.percent_complete * 100).toFixed(0);
        },
        getCourseProgress () {
            const StoreViewed = useStoreViewed();
            return (idCourse) => {
                if (!StoreViewed?.viewed?.length) return null;
                const courseLesson = StoreViewed?.viewed?.find(course => course.id_course === idCourse);
                if (!courseLesson) return null;
                return courseLesson;
            };
        },
    },
});
