<template>
    <div>
        <NuxtLayout>
            <NuxtPage/>
        </NuxtLayout>
    </div>
</template>

<script setup>
const appStore = useStoreApp();

onMounted(function () {
    window.addEventListener('focus', appStore.focusApp);
    window.addEventListener('blur', appStore.blurApp);
    appStore.mountApp();
});

// Sets default seo properties for efficient bundling of seo composable
const $config = useRuntimeConfig();
useSeo().buildHead({
    title: $config.public.siteName,
    description: $config.public.siteStrapline,
    index: false,
});
</script>
