export const useStoreTechniqueTuneups = defineStore('StoreTechniqueTuneups', {
    state: () => ({
        lessons: null,
        error: null,
    }),
    actions: {
        getTechniqueTuneupsLessons: async function () {
            this.error = null;
            const {course, error} = await useEntityCourse().get('andys-technique-tuneups');
            if (course) {
                this.lessons = course.course_lessons.reverse();
            }
            this.error = error;
        },
        reset: function () {
            this.lessons = null;
            this.error = null;
        },
    },
});
