export const useStoreSessions = defineStore('StoreSessions', {
    state: () => ({
        thing: 'sessions',
        sessions: null,
        error: null,
        filter: {
            paginate: {
                auto: false,
            },
            order: [
                {field: 'session_accessed', asc: false},
            ],
        },
    }),
    actions: {
        async getSessions () {
            this.error = null;
            ({sessions: this.sessions, error: this.error} = await useQuerySessions().get(this.filter));
        },
        async closeSession (session) {
            await useEntitySession().close(session.id_session);
            await this.getSessions();
        },
        reset () {
            this.sessions = null;
            this.error = null;
        },
    },
});
