export const useStoreLesson = defineStore('StoreLesson', {
    state: () => ({
        lesson: null,
        pathway: null,
        pathwayCourses: null,
        played: false,
        playedSoundslice: false,
        seek: null,
        error: null,
    }),
    actions: {
        refreshLesson () {
            if (!this.lesson?.lesson_slug) return;
            return this.getLesson({
                lessonSlug: this.lesson?.lesson_slug,
                courseSlug: this.lesson?.lesson_course?.course_slug,
                pathwaySlug: this.pathway?.tag_slug
            });
        },
        async getLesson ({lessonSlug, courseSlug, pathwaySlug}) {
            let is404 = false;
            if (this.lesson?.lesson_slug !== lessonSlug) {
                this.lesson = null;
                this.pathway = null;
                this.pathwayCourses = null;
                this.played = false;
                this.playedSoundslice = false;
                this.seek = null;
            }
            this.error = null;
            ({
                lesson: this.lesson,
                pathway: this.pathway,
                pathwayCourses: this.pathwayCourses,
                error: this.error,
                is404,
            } = await useEntityLesson().get(lessonSlug, courseSlug, pathwaySlug));
            if (is404) {
                const event = useRequestEvent();
                setResponseStatus(event, 404);
            }
        },
        onViewLesson () {
            if (!this.lesson?.lesson_slug) return;
            return useEntityAction().postActionViewLesson(this.lesson.lesson_slug, this.lesson.lesson_locked);
        },
        onPlayVideo () {
            // Only fire if the play event is not part of a seeking action
            this.seek ? this.seek = false : console.log('Video Played');
            this.onPlayOnce();
        },
        onSeekVideo () {
            // Set this to ignore play events triggered by a seek
            this.seek = true;
            console.log('Video Seeked');
        },
        onStopVideo (event) {
            if (!event.target.seeking) console.log('Video Stopped');
        },
        onYoutubePlayerLoaded (e) {
            console.log('Youtube Player Loaded', e.target);
            this.onPlayOnce();
        },
        onSoundslicePlayAction () {
            if (this.playedSoundslice) return;
            this.playedSoundslice = true;
            useEntityAction().postActionPlaySoundslice(this.lesson.lesson_slug);
            this.onPlayOnce();
        },
        async appendLessonToHistory () {
            if (!useStoreAuth().isLoggedIn) return Promise.resolve();
            await useEntityView().post(this.lesson.id_lesson);
            await useStoreContinue().getContinue();
        },
        onPlayOnce () {
            // This function should only be run once per view of the lesson, but could be triggered by playing any kind of embed
            if (this.played) return;
            this.played = true;
            this.appendLessonToHistory();
        },
        reset: function () {
            this.lesson = null;
            this.pathway = null;
            this.pathwayCourses = null;
            this.played = false;
            this.playedSoundslice = false;
            this.seek = null;
            this.error = null;
        },
    },
    getters: {
        nextCourse () {
            if (!this.pathwayCourses || !this.pathwayCourses.length === 0) return null;
            if (!this.lesson) return null;
            if (!this.lesson.lesson_course) return null;
            if (!this.lesson.lesson_course.course_slug) return null;
            const currentCourseIndex = this.pathwayCourses.findIndex(course => course.course_slug === this.lesson.lesson_course.course_slug);
            if (currentCourseIndex === -1) return null;
            const nextCourse = this.pathwayCourses[currentCourseIndex + 1];
            if (nextCourse) return nextCourse;
            return null;
        },
    },
});
