export default defineNuxtRouteMiddleware((to, from) => {
    const EntityAction = useEntityAction();

    function pageLoad () {
        if (to.name === 'videos' && from.name === 'videos') return;
        EntityAction.postActionPageLoad(to.fullPath);
    }
    function trackedPageLoad () {
        if (to.query?.campaign || to.query?.source) {
            if (import.meta.server) return; // Don't run server-side
            const params = [];
            if (to.query?.campaign) params.push({key: 'campaign', value: to.query.campaign});
            if (to.query?.source) params.push({key: 'source', value: to.query.source});
            params.push({key: 'path', value: to.path});
            params.push({key: 'full_path', value: to.fullPath});
            if (document.referrer) params.push({key: 'referrer', value: document.referrer || 'unknown'});
            return EntityAction.postActionTrackedPageLoad(params);
        }
    }
    pageLoad();
    trackedPageLoad();
});
