<template>
    <div class="article-cookies-payment">
        <p>
            Payment cookies enable you to make payments on the website, and may involve third party cookies which track
            information about you.
        </p>
        <ul>
            <li>
                <strong>Stripe</strong>
                - Stripe use necessary cookies to identify you in order to provide a secure payment gateway, and
                streamline your checkouts, as described in their
                privacy policy - <a href="https://stripe.com/gb/privacy">https://stripe.com/gb/privacy</a>.
                (cookies used for this purpose are: Multiple Stripe Cookies).
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleCookiesPayment',
};
</script>
