export default function (api) {
    const {$api} = useNuxtApp();
    const $config = useRuntimeConfig().public;

    if(!$config.apis[api]) throw Error(`Attempting to construct api with api key '${api}' that doesn't exist in the config object.`);

    const baseURL = $config.apis[api];
    const methods = {};

    methods.get = async function (endpoint, filter, signal) {
        let data = null, status = null;
        const config = {baseURL};
        config.params = filter ? {filter: JSON.stringify(filter)} : undefined;
        config.signal = signal ? signal : undefined;
        await $api.get(endpoint, config)
            .then(response => {
                data = response?.data;
                status = response?.status;
            });
        return {data, status};
    };

    methods.post = async function (endpoint, params) {
        let data = null, status = null;
        const config = {baseURL};
        await $api.post(endpoint, params, config)
            .then(response => {
                data = response?.data;
                status = response?.status;
            });
        return {data, status};
    };

    methods.put = async function (endpoint, params) {
        let data = null, status = null;
        const config = {baseURL};
        await $api.put(endpoint, params, config)
            .then(response => {
                data = response?.data;
                status = response?.status;
            });
        return {data, status};
    };

    methods.patch = async function (endpoint, params) {
        let data = null, status = null;
        const config = {baseURL};
        await $api.patch(endpoint, params, config)
            .then(response => {
                data = response?.data;
                status = response?.status;
            });
        return {data, status};
    };

    methods.delete = async function (endpoint) {
        let data = null, status = null;
        const config = {baseURL};
        await $api.delete(endpoint, config)
            .then(response => {
                data = response?.data;
                status = response?.status;
            });
        return {data, status};
    };

    return methods;
}
