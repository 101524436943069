export const useStoreCourses = defineStore('StoreCourses', {
    state: () => ({
        courses: null,
        timeout: null,
        error: null,
        filter: {
            paginate: {
                auto: false,
            },
            order: [
                {field: 'course_title', asc: true},
                {field: 'id_course', asc: true},
            ],
            query: {
                value: '',
                fields: [
                    'course_title',
                    'course_slug',
                ],
            },
        },
    }),
    actions: {
        getCourses: async function () {
            this.error = null;
            ({courses: this.courses, error: this.error} = await useQueryCourses().get(this.filter));
        },
        makeSearch: function (search) {
            if (this.timeout) clearTimeout(this.timeout);
            this.courses = null;
            this.timeout = setTimeout(() => {
                this.filter.query.value = search;
                this.getCourses().finally();
            }, 500);
        },
        reset: function () {
            this.courses = null;
            this.error = null;
        },
    },
    getters: {
        searchQuery () {
            return this.filter.query.value;
        },
    },
});
