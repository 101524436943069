export default function () {
    const UtilityImages = {};
    const UtilityFunctions = useUtilityFunctions();

    const $config = useRuntimeConfig().public;

    UtilityImages.getImageUrl = function (filename, folder, fallback) {
        fallback = fallback || ('profile-image.gif');
        fallback = fallback || ('profile-image.gif');
        return UtilityFunctions.isString(filename) && !!filename ? $config.uploads + folder + '/' + filename : $config.images + fallback;
    };

    return UtilityImages;
}
