export const useStoreMembership = defineStore('StoreMembership', {
    state: () => ({
        subscription: null,
        product: null,
        plan: null,
        error: null
    }),
    actions: {
        getMembership: async function () {
            this.error = null;
            ({product: this.product, error: this.error} = await useEntityProduct().get('access-all-areas'));
            if (this.error) return;
            if (useStoreAuth().isLoggedIn) {
                let filter = {
                    order: [
                        {field: 'subscription_status', asc: true},
                    ],
                    fields: {
                        id_subscription_product: this.product.id_subscription_product,
                    },
                };
                const {subscriptions} = await useQuerySubscriptions().get(filter);
                for (let i = 0; i < subscriptions.length; ++i) {
                    if (subscriptions[i].subscription_status === 'active') {
                        this.subscription = subscriptions[0];
                        return null;
                    }
                    if (subscriptions[i].subscription_status !== 'cancelled') {
                        useRouter().replace({
                            name: 'me-subscriptions-idSubscription',
                            params: {idSubscription: subscriptions[i].id_subscription}
                        });
                        return null;
                    }
                }
                return this.product;
            }
        },
        selectPlan: function (plan) {
            this.plan = plan;
            useEntityAction().postActionSelectAAAPlan(plan.subscription_plan_name);
        },
        clearPlan: function () {
            this.plan = null;
        },
        reset: function () {
            this.subscription = null;
            this.product = null;
            this.plan = null;
            this.error = null;
        },
    },
});
