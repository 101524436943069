<template>
    <div class="article-cookies-marketing">
        <p>
            We use marketing cookies to help us improve the relevancy of advertising campaigns you receive, both on the
            site and across the web.
        </p>
        <ul>
            <li>
                <strong>Facebook Pixel</strong>
                - Facebook use the Facebook Pixel to collect or receive information about your browsing habits and use
                that information to provide measurement services,
                and targeted adverts, as described in the <a href="https://www.facebook.com/about/privacy/"
                                                             target="_blank">Facebook Data Policy</a>.
                Our use of the Facebook Pixel allows us to serve your relevant adverts from our Facebook Business Page.
                You can opt-out of the collection and use of information for advert targeting across all websites you
                visit here
                - <a href="https://www.aboutads.info/choices" target="_blank">https://www.aboutads.info/choices</a>
                (cookies used for this purpose are: fr).
            </li>
            <li>
                <strong>Google AdWords</strong>
                - Google AdWords use cookies to collect or receive information about your browsing habits and use that
                information to provide measurement services,
                and targeted adverts, as described in their privacy policy - <a
                    href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
                Our use of Google AdWords allows us to serve you relevant adverts while you are using Google's services.
                You can opt-out of the collection and use of information for advert targeting across all websites you
                visit here
                - <a href="https://www.aboutads.info/choices" target="_blank">https://www.aboutads.info/choices</a>
                (cookies used for this purpose are: Multiple Google AdWords Cookies).
            </li>
            <li>
                <strong>Google Ads</strong>
                - Google uses cookies to collect or receive information about your browsing habits and use that
                information to provide measurement services,
                and targeted adverts, as described in their privacy policy - <a
                    href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
                Our use of Google ads allows us to serve you relevant adverts while you are using Google's services.
                You can opt-out of the collection and use of information for advert targeting across all websites you
                visit here
                - <a href="https://www.aboutads.info/choices" target="_blank">https://www.aboutads.info/choices</a>
                (cookies used for this purpose are: Multiple Google Ads Cookies).
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleCookiesMarketing',
};
</script>
