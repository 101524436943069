// Be sure to declare this outside the exported function to create singleton
let correlationId = null;

export default function () {

    const cookie = useCookieCorrelation();
    correlationId = cookie.get() || null;

    function acquireId () {
        if (!correlationId) generateId();
        return correlationId;
    }

    function resetId () {
        removeId();
        generateId();
    }

    function generateId () {
        let id = '';
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 45; i++) {
            id += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        correlationId = id;
        cookie.set(id);
    }

    function removeId () {
        correlationId = null;
        cookie.remove();
    }

    return {acquireId, resetId};
}
