export const useStoreSubscriptions = defineStore('StoreSubscriptions', {
    state: () => ({
        thing: 'subscriptions',
        subscriptions: null,
        timeout: null,
        error: null,
        filter: {
            paginate: {
                auto: false,
            },
            order: [
                {field: 'subscription_created', asc: false},
            ],
        },
    }),
    actions: {
        getSubscriptions: async function () {
            this.error = null;
            ({subscriptions: this.subscriptions, error: this.error} = await useQuerySubscriptions().get(this.filter));
        },
        reset: function () {
            this.subscriptions = null;
            this.error = null;
        },
    },
});
