export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('vue:error', (err, instance) => {
        const pattern = /(?<=at )\S+/g;
        const frames = [...err.stack.matchAll(pattern)].map(m => m[0]);
        const errorObject = {
            message: err?.message,
            route: instance?.$route?.path,
            frames,
        };
        if (import.meta.server) console.error('[VUE SERVER]', JSON.stringify(errorObject));
        if (import.meta.client) $fetch('/api/error', {params: {error: JSON.stringify(errorObject)}}).finally();
    });
});
