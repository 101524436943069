export default function () {

    async function getAddress (idParty) {
        let address = null, error = null;
        try {
            const {data} = await useApi('finance').get('me/parties/' + idParty + '/address');
            address = data.address;
        } catch (e) {
            error = useApiError(e);
        }
        return {address, error};
    }

    async function postAddress (idParty, params) {
        let address = null, error = null;
        try {
            const {data} = await useApi('finance').post('me/parties/' + idParty + '/address', params);
            address = data.address;
        } catch (e) {
            error = useApiError(e);
        }
        return {address, error};
    }

    return {getAddress, postAddress};
}
