<script>
import {h} from 'vue';

export default {
    name: 'ContentCompiled',
    // https://stackoverflow.com/questions/70957780/how-i-can-render-a-component-from-a-string-with-vue-3
    props: {
        html: {
            type: String,
            default: '',
        },
    },
    render() {
        const r = {
            template: `
                <div class="content-compiled">${this.html || ''}</div>`,
            /**
            methods: {
                hello () {
                    // method "hello" is also available here
                },
            },
            **/
        };
        return h(r);
    },
};
</script>
