export const useStoreAuth = defineStore('StoreAuth', {
    state: () => ({
        currentUser: null,
        isLoggedIn: null,
    }),
    actions: {
        async setCurrentUser (token) {
            this.isLoggedIn = useEntityMe().statusFlags.unknown;
            this.currentUser = null;
            useApiToken().set(token);
            await this.getCurrentUser();
        },
        async initCurrentUser () {
            const apiToken = useApiToken();
            if (!apiToken.get()) {
                this.currentUser = {
                    'aaa_community_discord_link': null,
                    'eligible_for_aaa_free_trial': 1,
                    'has_aaa_membership': 0,
                };
                this.isLoggedIn = false;
                return;
            }
            await this.getCurrentUser();
        },
        async getCurrentUser () {
            ({currentUser: this.currentUser, isLoggedIn: this.isLoggedIn} = await useEntityMe().get('get-me'));
            if (this.isLoggedIn) {
                useStoreThreadsUnread().startUnreadThreadPoller();
            } else {
                useStoreThreadsUnread().stopUnreadThreadPoller();
            }
        },
        async logoutCurrentUser () {
            await useEntityMe().logout();
            useApiCorrelation().resetId();
            useStoreLogin().initLogin(null);
            await this.clearCurrentUser();
            useRouter().replace('/').catch(() => {});
        },
        async clearCurrentUser () {
            useApiToken().remove();
            await this.getCurrentUser();
            useStoreCourse().reset();
            useStoreCourses().reset();
            useStoreHelpdesk().reset();
            useStoreLesson().reset();
            useStoreMembership().reset();
            useStoreSessions().reset();
            useStoreSubscription().reset();
            useStoreSubscriptionAdd().reset();
            useStoreSubscriptions().reset();
            useStoreTechniqueTuneups().reset();
            useStoreThread().reset();
            useStoreThreads().reset();
            useStoreThreadsUnread().reset();
            useStoreUnsubscribes().reset();
            useStoreUserLessons().reset();
        },
    },
    getters: {
        isAdmin () {
            return this.hasRole(['Superadmin', 'Admin']);
        },
        isDirector () {
            return this.hasRole(['Superadmin', 'Admin', 'Director']);
        },
        isStaff () {
            return this.hasRole(['Superadmin', 'Admin', 'Director', 'Staff']);
        },
        isEditor () {
            return this.hasRole(['Superadmin', 'Admin', 'Director','Editor']);
        },
        isGuest () {
            return this.hasRole(['Guest']);
        },
        hasRole () {
            return (roles) => {
                if (!this.currentUser || !this.currentUser.roles) return false;
                for (let i = 0; i < roles.length; ++i) {
                    let test = roles[i];
                    if (this.currentUser.roles.includes(test)) return true;
                }
                return false;
            };
        },
    },
});
