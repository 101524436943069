export default function () {

    const EntityLesson = useEntityLesson();

    async function get (filter) {
        let userLessons = null, error = null, hasMore = false;
        try {
            const {data} = await useApi('lessons').get('me/user-lessons', filter);
            userLessons = data.user_lessons.map((lesson) => {
                return EntityLesson.build(lesson);
            });
            hasMore = (userLessons.length === filter?.paginate?.limit);
        } catch (e) {
            error = useApiError(e);
        }
        return {userLessons, error, hasMore};
    }

    return {get};
}
