<template>
    <div class="article-answer-why-account">
        <p>
            With a 100% free account you gain access to advanced features of the website, including advanced search and
            ability to track and save your progress.
        </p>
        <p>
            There is lots of free content to enjoy but you may wish to gain access to all content with the Access All Areas membership. The membership exists to help fund the website and enable Andy to produce more courses and lessons.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerWhyAccount',
};
</script>
