export const useStoreApp = defineStore('StoreApp', {
    state: () => ({
        mounted: false,
        blurred: false,
        muted: false,
        initialized: false,
    }),
    actions: {
        async initApp () {
            if (this.initialized) return;
            await Promise.all([
                useStoreAuth().initCurrentUser(),
                useStoreFeatures().initFeatures(),
            ]);
            this.initialized = true;
        },
        // We don't need to get current user on app mount as this is handled by auth.js middleware
        mountApp () {
            if (this.mounted) return true;
            this.mounted = true;
            const $config = useRuntimeConfig();
            console.log('App Mounted!', `Nuxt App Version ${$config.public.appVersion} | ${$config.public.gitHash}`);
            const cookieStore = useStoreCookies();
            cookieStore.initCookies();
            const storeThreadsUnread = useStoreThreadsUnread();
            storeThreadsUnread.startUnreadThreadPoller(null);
        },
        blurApp () {
            if (this.blurred) return true;
            this.blurred = true;
            console.log('App Blurred!');
            const storeThreadsUnread = useStoreThreadsUnread();
            storeThreadsUnread.stopUnreadThreadPoller(null);
        },
        focusApp () {
            if (!this.blurred) return true;
            this.blurred = false;
            console.log('App Focused!');
            const storeAuth = useStoreAuth();
            storeAuth.getCurrentUser(null); // Kicks off unread poller if user is signed in
        },
        muteApp () {
            this.muted = true;
            console.log('App Muted!');
        },
        unmuteApp () {
            this.muted = false;
            console.log('App Unmuted!');
        },
    },
});
