export const useStoreHelpdesk = defineStore('StoreHelpdesk', {
    state: () => ({
        formRef: 'formHelpdesk',
        helpdesk: null,
        disabled: null,
        success: null,
        error: null,
    }),
    actions: {
        initHelpdesk () {
            const storeAuth = useStoreAuth();
            this.error = null;
            this.success = null;
            this.helpdesk = {
                first_name: storeAuth.currentUser && storeAuth.currentUser.first_name ? storeAuth.currentUser.first_name : null,
                last_name: storeAuth.currentUser && storeAuth.currentUser.last_name ? storeAuth.currentUser.last_name : null,
                email: storeAuth.currentUser && storeAuth.currentUser.email ? storeAuth.currentUser.email : null,
                telephone: storeAuth.currentUser && storeAuth.currentUser.telephone ? storeAuth.currentUser.telephone : null,
                message: null,
                recaptcha_response: null,
                notifications_mailers: null,
                notifications_mailers_consent_method: null,
                notifications_mailers_title: null,
                notifications_mailers_text: null,
                notifications_mailers_info: null,
                permissions: {
                    3: true, // ID of user account controller policy
                },
            };
        },
        updateHelpdeskField ({field, value}) {
            if (this.helpdesk[field] === undefined) throw new Error('Attempted to update a signup field which does not exist (' + field + ').');
            this.helpdesk[field] = value;
        },
        async postHelpdesk () {
            this.error = null;
            this.success = null;
            this.disabled = true;
            ({thread: this.thread, error: this.error} = await useEntityThread().post(this.helpdesk));
            if (this.thread.jwt) {
                await useStoreAuth().setCurrentUser(this.thread.jwt);
            }
            if (this.thread.id_thread) {
                await useRouter().push('/messages/' + this.thread.id_thread);
                this.initHelpdesk();
            } else {
                this.success = 'Thanks, your message has been sent. We\'ll be in touch soon.';
            }
            this.disabled = false;
        },
        reset () {
            if (this.helpdesk) this.initHelpdesk();
            this.disabled = null;
            this.success = null;
            this.error = null;
        },
    }
});
