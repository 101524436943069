// Abstraction over YouTube Player API
// See https://developers.google.com/youtube/iframe_api_reference

let youtubeScriptTag;

let resolveCallers = [];

function callResolvers () {
    resolveCallers.forEach(resolver => resolver(window.YT));
}

const youtubeApi = {
    load () {
        return new Promise((resolve, reject) => {
            if (import.meta.server) {
                reject(new Error('Attempted to load the YouTube API server side. You might be doing this in a Vue lifecycle hook that runs server side.'));
                return;
            }

            if (window.YT) {
                resolve(window.YT);
                return;
            }

            if (!youtubeScriptTag) {
                youtubeScriptTag = document.createElement('script');
                youtubeScriptTag.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(youtubeScriptTag, firstScriptTag);
                resolveCallers.push(resolve);
                window.onYouTubeIframeAPIReady = () => {
                    callResolvers();
                };
                return;
            }

            resolveCallers.push(resolve);
        });
    },
    registerPlayerEventListener (youtubePlayerIframeElement, events) {
        return this.load().then(ytApi => new ytApi.Player(youtubePlayerIframeElement, { events }));
    }
};

export default defineNuxtPlugin(nuxt => {
    nuxt.provide('youtubeApi', youtubeApi);
});
