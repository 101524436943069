export default function () {

    const entityThread = useEntityThread();

    async function get (filter) {
        let threads = null, error = null, hasMore = false;
        try {
            const {data} = await useApi('messages').get('threads', filter);
            threads = data.threads.map((thread) => {
                return entityThread.build(thread);
            });
            hasMore = (threads.length === filter?.paginate?.limit);
        } catch (e) {
            error = useApiError(e);
        }
        return {threads, error, hasMore};
    }

    return {get};
}
