export const useStoreThread = defineStore('StoreThread', {
    state: () => ({
        token: null,
        thread: null,
        messages: null,
        message: null,
        disabled: null,
        shouldScroll: null,
        lastMessage: null,
        poller: null,
        paused: null,
        hasMore: null,
        sendError: null,
        error: null,
        filter: {
            paginate: {
                start: 0,
                limit: 35,
            },
            order: [
                {field: 'message_created', asc: false},
            ],
            query: {
                value: '',
                fields: [
                    'first_name',
                    'last_name',
                    'email',
                    'message_content',
                ],
            },
        },
    }),
    actions: {
        setToken (token) {
            this.token = useStoreAuth().isLoggedIn ? undefined : token;
        },
        async getThread (idThread) {
            this.error = null;
            if (this.thread && this.thread.id_thread && this.thread.id_thread !== idThread) {
                this.thread = null;
                this.messages = null;
                this.lastMessage = null;
                this.message = null;
                this.disabled = null;
                this.sendError = null;
            }
            if (!idThread) return false;
            ({thread: this.thread, error: this.error} = await useEntityThread().get(idThread, this.token));
            if (this.error && !useStoreAuth().isLoggedIn) {
                return useRouter().replace('/login');
            }
            if (this.thread) {
                this.startMessagesPoller();
                return this.getMessages();
            }
        },
        stopMessagesPoller () {
            this.paused = true;
        },
        startMessagesPoller () {
            this.paused = false;
            if (!this.poller) {
                this.pollMessages();
            }
            this.getMessages();
        },
        pollMessages () {
            if (this.poller) return false;
            this.poller = setInterval(() => {
                this.getMessages();
            }, 3000);
        },
        async getMessages () {
            const storeAuth = useStoreAuth();
            if ((!storeAuth.isLoggedIn && !this.token) || this.paused || !this.thread || !this.thread.id_thread) return false;
            const {messages, error, hasMore} = await useQueryThreadMessages().get(this.thread.id_thread, this.filter, this.token);
            this.error = error;
            if (!this.thread?.id_thread) return;
            if (messages !== null) {
                if (messages.length > 0 && (!this.messages || messages.length !== this.messages.length) && (!this.lastMessage || this.lastMessage.id_message !== messages[messages.length - 1].id_message)) {
                    this.shouldScroll = true;
                    this.readThread(this.thread.id_thread);
                    const storeThreads = useStoreThreads();
                    storeThreads.stopThreadsPoller({});
                }
                if (!this.paused && this.thread && this.thread.id_thread) {
                    this.messages = messages;
                    this.lastMessage = messages?.length ? messages[messages.length - 1] : null;
                    this.hasMore = hasMore;
                }
                if (this.error) this.error = null;
            }
        },
        async readThread (idThread) {
            if (!this.token) {
                const storeThreads = useStoreThreads();
                storeThreads.readThread({id_thread: idThread});
            }
            await useEntityThread().read(idThread, this.token);
            useStoreThreads().startThreadsPoller();
            useStoreThreadsUnread().getUnreadThreads().then();
        },
        clearScroll () {
            this.shouldScroll = false;
        },
        updateMessage ({message}) {
            this.message = message;
        },
        async sendMessage () {
            if (!this.thread || !this.thread.id_thread || !this.message) return false;
            this.sendError = null;
            this.disabled = true;
            ({error: this.sendError} = await useEntityThread().message(this.thread.id_thread, this.message, this.token));
            if (!this.sendError) {
                await this.getMessages();
                this.message = null;
                this.disabled = false;
            } else {
                this.shouldScroll = true;
                this.disabled = false;
            }
        },
        loadMore () {
            if (!this.hasMore) return false;
            this.filter.paginate.limit = this.filter.paginate.limit + 35;
        },
        reset () {
            this.paused = true;
            this.thread = null;
            this.messages = null;
            this.lastMessage = null;
            this.message = null;
            this.disabled = null;
            this.filter.paginate.limit = 35;
            this.hasMore = null;
            this.sendError = null;
            this.error = null;
        },
    },
});
