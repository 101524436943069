import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArticleAnswerAppCast, LazyArticleAnswerAppDownload, LazyArticleAnswerAppPassword, LazyArticleAnswerAppSync, LazyArticleAnswerAppVideos, LazyArticleAnswerChangeEmail, LazyArticleAnswerChordSheets, LazyArticleAnswerDownloadEbooks, LazyArticleAnswerDownloadTracks, LazyArticleAnswerForgotPassword, LazyArticleAnswerHowCancel, LazyArticleAnswerHowCancelFaq, LazyArticleAnswerHowMuch, LazyArticleAnswerHowWatch, LazyArticleAnswerIncludedAaa, LazyArticleAnswerPaypalAccepted, LazyArticleAnswerPrivateLessons, LazyArticleAnswerTalkAndy, LazyArticleAnswerWhatAaa, LazyArticleAnswerWhatAndy, LazyArticleAnswerWhyAccount, LazyArticleAnswerWhyAndy, LazyArticleCookies, LazyArticleCookiesAnalytical, LazyArticleCookiesAudio, LazyArticleCookiesMarketing, LazyArticleCookiesNecessary, LazyArticleCookiesPayment, LazyArticleCookiesVideo, LazyArticleCookiesWhatAre, LazyArticleDiscordPrompt, LazyArticleDonations, LazyArticleLiveDescription, LazyArticleLiveStreamFallbackDescription, LazyArticleMembershipSellingPoints, LazyArticlePrivacyPolicy, LazyArticleTerms, LazyArticleWelcomeTrialAvailable, LazyArticleWelcomeTrialUnavailable, LazyArticleWhyDownloadTheApp, LazyArticleWhyNoIosApp, LazyIconBook, LazyIconCheck, LazyIconCheckBadge, LazyIconChevronDown, LazyIconChevronLeft, LazyIconChevronRight, LazyIconChevronUp, LazyIconClose, LazyIconCourses, LazyIconCreditCard, LazyIconDocument, LazyIconDownload, LazyIconEdit, LazyIconEye, LazyIconHelp, LazyIconHome, LazyIconLessons, LazyIconLive, LazyIconLocation, LazyIconLocked, LazyIconLockedOutline, LazyIconLogin, LazyIconMail, LazyIconMinus, LazyIconPlay, LazyIconPlus, LazyIconSearch, LazyIconSongs, LazyIconTag, LazyIconUnlock, LazyIconUserCircle, LazyImageOptimized } from '#components'
const lazyGlobalComponents = [
  ["ArticleAnswerAppCast", LazyArticleAnswerAppCast],
["ArticleAnswerAppDownload", LazyArticleAnswerAppDownload],
["ArticleAnswerAppPassword", LazyArticleAnswerAppPassword],
["ArticleAnswerAppSync", LazyArticleAnswerAppSync],
["ArticleAnswerAppVideos", LazyArticleAnswerAppVideos],
["ArticleAnswerChangeEmail", LazyArticleAnswerChangeEmail],
["ArticleAnswerChordSheets", LazyArticleAnswerChordSheets],
["ArticleAnswerDownloadEbooks", LazyArticleAnswerDownloadEbooks],
["ArticleAnswerDownloadTracks", LazyArticleAnswerDownloadTracks],
["ArticleAnswerForgotPassword", LazyArticleAnswerForgotPassword],
["ArticleAnswerHowCancel", LazyArticleAnswerHowCancel],
["ArticleAnswerHowCancelFaq", LazyArticleAnswerHowCancelFaq],
["ArticleAnswerHowMuch", LazyArticleAnswerHowMuch],
["ArticleAnswerHowWatch", LazyArticleAnswerHowWatch],
["ArticleAnswerIncludedAaa", LazyArticleAnswerIncludedAaa],
["ArticleAnswerPaypalAccepted", LazyArticleAnswerPaypalAccepted],
["ArticleAnswerPrivateLessons", LazyArticleAnswerPrivateLessons],
["ArticleAnswerTalkAndy", LazyArticleAnswerTalkAndy],
["ArticleAnswerWhatAaa", LazyArticleAnswerWhatAaa],
["ArticleAnswerWhatAndy", LazyArticleAnswerWhatAndy],
["ArticleAnswerWhyAccount", LazyArticleAnswerWhyAccount],
["ArticleAnswerWhyAndy", LazyArticleAnswerWhyAndy],
["ArticleCookies", LazyArticleCookies],
["ArticleCookiesAnalytical", LazyArticleCookiesAnalytical],
["ArticleCookiesAudio", LazyArticleCookiesAudio],
["ArticleCookiesMarketing", LazyArticleCookiesMarketing],
["ArticleCookiesNecessary", LazyArticleCookiesNecessary],
["ArticleCookiesPayment", LazyArticleCookiesPayment],
["ArticleCookiesVideo", LazyArticleCookiesVideo],
["ArticleCookiesWhatAre", LazyArticleCookiesWhatAre],
["ArticleDiscordPrompt", LazyArticleDiscordPrompt],
["ArticleDonations", LazyArticleDonations],
["ArticleLiveDescription", LazyArticleLiveDescription],
["ArticleLiveStreamFallbackDescription", LazyArticleLiveStreamFallbackDescription],
["ArticleMembershipSellingPoints", LazyArticleMembershipSellingPoints],
["ArticlePrivacyPolicy", LazyArticlePrivacyPolicy],
["ArticleTerms", LazyArticleTerms],
["ArticleWelcomeTrialAvailable", LazyArticleWelcomeTrialAvailable],
["ArticleWelcomeTrialUnavailable", LazyArticleWelcomeTrialUnavailable],
["ArticleWhyDownloadTheApp", LazyArticleWhyDownloadTheApp],
["ArticleWhyNoIosApp", LazyArticleWhyNoIosApp],
["IconBook", LazyIconBook],
["IconCheck", LazyIconCheck],
["IconCheckBadge", LazyIconCheckBadge],
["IconChevronDown", LazyIconChevronDown],
["IconChevronLeft", LazyIconChevronLeft],
["IconChevronRight", LazyIconChevronRight],
["IconChevronUp", LazyIconChevronUp],
["IconClose", LazyIconClose],
["IconCourses", LazyIconCourses],
["IconCreditCard", LazyIconCreditCard],
["IconDocument", LazyIconDocument],
["IconDownload", LazyIconDownload],
["IconEdit", LazyIconEdit],
["IconEye", LazyIconEye],
["IconHelp", LazyIconHelp],
["IconHome", LazyIconHome],
["IconLessons", LazyIconLessons],
["IconLive", LazyIconLive],
["IconLocation", LazyIconLocation],
["IconLocked", LazyIconLocked],
["IconLockedOutline", LazyIconLockedOutline],
["IconLogin", LazyIconLogin],
["IconMail", LazyIconMail],
["IconMinus", LazyIconMinus],
["IconPlay", LazyIconPlay],
["IconPlus", LazyIconPlus],
["IconSearch", LazyIconSearch],
["IconSongs", LazyIconSongs],
["IconTag", LazyIconTag],
["IconUnlock", LazyIconUnlock],
["IconUserCircle", LazyIconUserCircle],
["ImageOptimized", LazyImageOptimized],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
