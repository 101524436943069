export default function () {

    async function post (email) {
        let error = null;
        let params = {
            email: email,
        };
        try {
            await useApi('auth').post('me/passcodes', params);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    async function put (params) {
        let jwt = null, error = null;
        try {
            const {data} = await useApi('auth').put('me/passcodes', params);
            jwt = data.token;
        } catch (e) {
            error = useApiError(e);
        }
        return {jwt, error};
    }

    return {post, put};
};
