export default function () {

    async function post (idLesson) {
        let lessonView = null, error = null;
        let params = {
            id_lesson: idLesson,
        };
        try {
            const {data} = await useApi('onboard').post('viewed', params);
            lessonView = data.lesson_view;
        } catch (e) {
            error = useApiError(e);
        }
        return {lessonView, error};
    }

    async function remove (idUserLessonView) {
        let error = null;
        try {
            await useApi('onboard').delete('viewed' + idUserLessonView);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    return {post, remove};
}
