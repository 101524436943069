<template>
    <div class="article-cookies-what-are">
        <p>
            Cookies are small text files that are placed on your computer by websites that you visit. They are widely
            used in order to make websites work,
            or work more efficiently, as well as to provide information to the owners of the site and in some cases for
            marketing purposes to serve you relevant advertisements.
        </p>
        <p>
            If you would prefer not to receive cookies at all, you can modify your browser so that it notifies you when
            cookies are sent to it or you can refuse cookies altogether.
            You can also delete cookies that have already been set.
        </p>
        <p>
            You can obtain up-to-date information about blocking and deleting cookies via these links:
        </p>
        <ul>
            <li>
                <a href="https://support.google.com/chrome/answer/95647?hl=en">
                    https://support.google.com/chrome/answer/95647?hl=en</a> (Chrome)
            </li>
            <li>
                <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                    https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a> (Firefox)
            </li>
            <li>
                <a href="https://www.opera.com/help/tutorials/security/cookies/">
                    https://www.opera.com/help/tutorials/security/cookies/</a> (Opera)
            </li>
            <li>
                <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
                    https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                (Internet Explorer)
            </li>
            <li>
                <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">
                    https://support.apple.com/en-gb/guide/safari/sfri11471/mac</a> (Safari)
            </li>
            <li>
                <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
                    https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a> (Edge)
            </li>
        </ul>
        <p>
            To opt out of being tracked by Google Analytics across all websites visit <a
                href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
        </p>
        <p>
            Alternatively, you may wish to visit <a href="https://www.aboutcookies.org">www.aboutcookies.org</a>, which
            contains comprehensive information on how to manage your cookies in a range of different browsers.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleCookiesWhatAre',
};
</script>
