<template>
    <div class="article-answer-how-cancel-faq">
        <p>
            You can cancel at any time from within your account settings, or send us a message from the website. Andy also offers a 60-day money back guarantee if you are not completely satisfied.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerHowCancelFaq',
};
</script>
