<template>
    <p class="font-semibold pt-5" v-if="currentUser?.has_aaa_membership">
        Connect with Andy and other AAA members between live sessions with the
        <NuxtLink :to="currentUser?.aaa_community_discord_link">Andy Guitar AAA Discord community</NuxtLink>.
    </p>
</template>

<script>
import {mapState} from 'pinia';

export default {
    name: "ArticleDiscordPrompt",
    computed: {
        ...mapState(useStoreAuth, {
            currentUser: 'currentUser',
        }),
    },
};
</script>
