export default defineNuxtPlugin(nuxt => {
    const videoUtilities = {};

    videoUtilities._parseCloudfrontVideoSrc = function (videoSrc) {
        if (!videoSrc) return null;
        let match = videoSrc.match(/([a-zA-Z0-9-_]+)\.cloudfront\.net\/videos-general\/([a-zA-Z0-9-_]+).mp4/);
        if (!match) return null;
        if (match.length !== 3) return null;
        const cloudfrontNamespace = match[1];
        const fileName = match[2];
        if (!fileName || !cloudfrontNamespace) return null;
        return {fileName, cloudfrontNamespace};
    };

    videoUtilities.getCloudfrontVideoSources = function (videoSrc) {
        const fileInfo = videoUtilities._parseCloudfrontVideoSrc(videoSrc);
        if (!fileInfo) return { src: videoSrc }; // Fallback to whatever was provided if invalid
        const { fileName, cloudfrontNamespace } = fileInfo;
        return [
            // { src: `https://${cloudfrontNamespace}.cloudfront.net/videos-general/webm/${fileName}.webm`, type: 'video/webm; codecs="vp8, vorbis"' }, // Prefer WebM
            { src: `https://${cloudfrontNamespace}.cloudfront.net/videos-general/mp4/${fileName}.mp4`, type: 'video/mp4;' }, // Transcoded MP4
            { src: `https://${cloudfrontNamespace}.cloudfront.net/videos-general/${fileName}.mp4`, type: 'video/mp4;' }, // Fail to original file
        ];
    };

    nuxt.provide('videoUtilities', videoUtilities);
});
