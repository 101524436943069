<template>
    <div class="article-cookies">
        <p>
            This website uses cookies. {{ cookieNotice }}
            To find out how we use your personal data please consult our <NuxtLink to="/privacy">privacy policy</NuxtLink>.
        </p>
        <div v-if="!cookies.asked">
            <p>
                Your choice regarding cookies on this site:
            </p>
            <div class="flex flex-row space-x-3 pt-1">
                <ButtonPrimary
                    buttonText="Accept Defaults"
                    :buttonClick="acceptDefaultCookies"
                ></ButtonPrimary>
                <ButtonPrimary
                    buttonColor="bg-zinc-100 text-zinc-900 hover:bg-zinc-50"
                    buttonText="Custom Settings"
                    :buttonClick="customiseCookieSettings"
                ></ButtonPrimary>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'pinia';

export default {
    name: 'ArticleCookies',
    computed: {
        ...mapState(useStoreCookies, {
            cookies: 'cookies',
        }),
        ...mapGetters(useStoreCookies, {
            cookieNotice: 'cookieNotice',
        }),
    },
    methods: {
        ...mapActions(useStoreCookies, {
            acceptDefaultCookies: 'acceptDefaultCookies',
            customiseCookieSettings: 'customiseCookieSettings',
        }),
    },
};
</script>
