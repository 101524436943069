<template>
    <component class="content-heading" :is="headingComponent" :class="headingClasses">
        {{ heading }}
    </component>
</template>

<script>
export default {
    name: 'ContentHeading',
    props: {
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        marginBottom: {
            required: false,
            type: String,
            default: 'mb-0',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
    computed: {
        headingClasses () {
            const cls = {};
            cls['text-2xl font-semibold tracking-tight sm:text-4xl'] = true;
            cls[this.headingColor] = true;
            cls[this.marginBottom] = true;
            cls['text-center'] = !!this.textCentered;
            return cls;
        },
    },
};
</script>
