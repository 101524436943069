
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45andyAaFivEw9qxMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/about-andy.vue?macro=true";
import { default as andy_45guitar_45appQIcOlQWuPPMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/andy-guitar-app.vue?macro=true";
import { default as brand_45guidelinesagum9gAIULMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/brand-guidelines.vue?macro=true";
import { default as cookiesD4gOECH5DtMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/cookies.vue?macro=true";
import { default as _91courseSlug_93cfpGTCUbTvMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/courses/[courseSlug].vue?macro=true";
import { default as indexGpR4wBR4cbMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/courses/index.vue?macro=true";
import { default as donationsgCMylurC7GMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/donations.vue?macro=true";
import { default as events8W4KORKjcCMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/events.vue?macro=true";
import { default as featuresNMLJI3fuoqMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/features.vue?macro=true";
import { default as free_45christmas_45mini_45songbookHK533ngZDWMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/free-christmas-mini-songbook.vue?macro=true";
import { default as free_45resourcessZN6AH7755Meta } from "/codebuild/output/src2093463017/src/nuxt/pages/free-resources.vue?macro=true";
import { default as _91categorySlug_93mcifgSK1JJMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/helpdesk/[categorySlug].vue?macro=true";
import { default as index6ua71snayJMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/helpdesk/index.vue?macro=true";
import { default as indexq3mbMyf3CpMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/index.vue?macro=true";
import { default as _91streamSlug_93SYtysnDGHmMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/live/[streamSlug].vue?macro=true";
import { default as indexHh6yuCe3hAMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/live/index.vue?macro=true";
import { default as indexHuEKIHemPBMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/login-code/index.vue?macro=true";
import { default as _91passcode_93GjzD4cjdFMMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/login/index/[passcode].vue?macro=true";
import { default as indexHLpNbK4qHtMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/login/index.vue?macro=true";
import { default as editFgnzx6vgn5Meta } from "/codebuild/output/src2093463017/src/nuxt/pages/me/edit.vue?macro=true";
import { default as indexqMBmSq21VhMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/me/index.vue?macro=true";
import { default as notificationsNM80vbAIRmMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/me/notifications.vue?macro=true";
import { default as cancelBSKii0MOPJMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/me/subscriptions/[idSubscription]/cancel.vue?macro=true";
import { default as indexDZyrPsISjzMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/me/subscriptions/[idSubscription]/index.vue?macro=true";
import { default as membershipCprmfsPSXLMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/membership.vue?macro=true";
import { default as _91token_93Q4E15K8SidMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/messages/index/[idThread]/[token].vue?macro=true";
import { default as _91idThread_93yltcWh9Mn4Meta } from "/codebuild/output/src2093463017/src/nuxt/pages/messages/index/[idThread].vue?macro=true";
import { default as indexv0guKA0hj4Meta } from "/codebuild/output/src2093463017/src/nuxt/pages/messages/index.vue?macro=true";
import { default as my_45list881HD99RgQMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/my-list.vue?macro=true";
import { default as privacy0t06u4Qq6EMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/privacy.vue?macro=true";
import { default as signature_45productsymdZPGOqoRMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/signature-products.vue?macro=true";
import { default as signupaG9DnFkRvrMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/signup.vue?macro=true";
import { default as termsmieddPwJyNMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/terms.vue?macro=true";
import { default as _91token_93d8iDITzDOkMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/unsubscribe-mailers/[email]/[token].vue?macro=true";
import { default as _91token_93uO63T5IxynMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/unsubscribe/[email]/[token].vue?macro=true";
import { default as _91lessonSlug_93LF7SiFRBqkMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/videos/[lessonSlug].vue?macro=true";
import { default as indexpTQ3gUFLIVMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/videos/index.vue?macro=true";
import { default as welcomeBRHhVYzmzUMeta } from "/codebuild/output/src2093463017/src/nuxt/pages/welcome.vue?macro=true";
export default [
  {
    name: "about-andy",
    path: "/about-andy",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/about-andy.vue")
  },
  {
    name: "andy-guitar-app",
    path: "/andy-guitar-app",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/andy-guitar-app.vue")
  },
  {
    name: "brand-guidelines",
    path: "/brand-guidelines",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/brand-guidelines.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/cookies.vue")
  },
  {
    name: "courses-courseSlug",
    path: "/courses/:courseSlug()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/courses/[courseSlug].vue")
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/courses/index.vue")
  },
  {
    name: "donations",
    path: "/donations",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/donations.vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/events.vue")
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/features.vue")
  },
  {
    name: "free-christmas-mini-songbook",
    path: "/free-christmas-mini-songbook",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/free-christmas-mini-songbook.vue")
  },
  {
    name: "free-resources",
    path: "/free-resources",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/free-resources.vue")
  },
  {
    name: "helpdesk-categorySlug",
    path: "/helpdesk/:categorySlug()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/helpdesk/[categorySlug].vue")
  },
  {
    name: "helpdesk",
    path: "/helpdesk",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/helpdesk/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/index.vue")
  },
  {
    name: "live-streamSlug",
    path: "/live/:streamSlug()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/live/[streamSlug].vue")
  },
  {
    name: "live",
    path: "/live",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/live/index.vue")
  },
  {
    name: "login-code",
    path: "/login-code",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/login-code/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/login/index.vue"),
    children: [
  {
    name: "login-index-passcode",
    path: ":passcode()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/login/index/[passcode].vue")
  }
]
  },
  {
    name: "me-edit",
    path: "/me/edit",
    meta: editFgnzx6vgn5Meta || {},
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/me/edit.vue")
  },
  {
    name: "me",
    path: "/me",
    meta: indexqMBmSq21VhMeta || {},
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/me/index.vue")
  },
  {
    name: "me-notifications",
    path: "/me/notifications",
    meta: notificationsNM80vbAIRmMeta || {},
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/me/notifications.vue")
  },
  {
    name: "me-subscriptions-idSubscription-cancel",
    path: "/me/subscriptions/:idSubscription()/cancel",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/me/subscriptions/[idSubscription]/cancel.vue")
  },
  {
    name: "me-subscriptions-idSubscription",
    path: "/me/subscriptions/:idSubscription()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/me/subscriptions/[idSubscription]/index.vue")
  },
  {
    name: "membership",
    path: "/membership",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/membership.vue")
  },
  {
    name: "messages",
    path: "/messages",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/messages/index.vue"),
    children: [
  {
    name: "messages-index-idThread",
    path: ":idThread()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/messages/index/[idThread].vue"),
    children: [
  {
    name: "messages-index-idThread-token",
    path: ":token()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/messages/index/[idThread]/[token].vue")
  }
]
  }
]
  },
  {
    name: "my-list",
    path: "/my-list",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/my-list.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/privacy.vue")
  },
  {
    name: "signature-products",
    path: "/signature-products",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/signature-products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/signup.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/terms.vue")
  },
  {
    name: "unsubscribe-mailers-email-token",
    path: "/unsubscribe-mailers/:email()/:token()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/unsubscribe-mailers/[email]/[token].vue")
  },
  {
    name: "unsubscribe-email-token",
    path: "/unsubscribe/:email()/:token()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/unsubscribe/[email]/[token].vue")
  },
  {
    name: "videos-lessonSlug",
    path: "/videos/:lessonSlug()",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/videos/[lessonSlug].vue")
  },
  {
    name: "videos",
    path: "/videos",
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/videos/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeBRHhVYzmzUMeta || {},
    component: () => import("/codebuild/output/src2093463017/src/nuxt/pages/welcome.vue")
  }
]