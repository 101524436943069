<template>
    <div class="article-answer-what-aaa">
        <p>
            With an Access All Areas membership you get full access to every course and lesson on the site, exclusive
            content, guest teacher courses and interactive TAB.
        </p>
        <p>
            You also have access to weekly live streams with Andy and to the ever-growing Discord community
            where like-minded students can talk and share their progress.
        </p>
        <p>
            If you are not satisfied, you can cancel any time. Andy also offers a 60-day money back
            guarantee.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerWhatAaa',
};
</script>
