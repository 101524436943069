function defineFeature (key, title, description, allowedValues) {
    return {key, allowedValues, title, description};
}

function defineValue (value, percentActive) {
    return {value, percentActive};
}

function defineValues (...values) {
    let percentCheck = 0;
    for (let i = 0; i < values.length; ++i) {
        percentCheck = percentCheck + values[i].percentActive;
    }
    const roundedTotalPercent = Math.round(percentCheck);
    if (roundedTotalPercent !== 100) throw new Error(`percentActive of all values does not total 100. Total: ${roundedTotalPercent}.`);
    return [...values];
}

export const useStoreFeatures = defineStore('StoreFeatures', {
    state: () => ({
        features: [
            defineFeature('featuresLinkInFooter', 'Features Link In Footer', 'Enable a link to this page in the footer of the website.', defineValues(
                defineValue(true, 0),
                defineValue(false, 100),
            )),
            defineFeature('optimizeImages', 'Optimise Images', 'Uses a new component to optimise images.', defineValues(
                defineValue(true, 100),
                defineValue(false, 0),
            )),
        ],
        featureValues: [],
    }),
    actions: {
        async initFeatures () {
            // Get the value for each feature
            const initialValues = this.features.map(feature => {
                // If it's already in the store, use that
                const storeValue = this.getFeatureValue(feature.key);
                if (storeValue !== undefined) return {key: feature.key, value: storeValue};

                // Else, if there's a cookie, use that
                const cookieValue = useCookie(feature.key).value;
                if (cookieValue !== undefined) return {key: feature.key, value: cookieValue};

                // Else, generate randomly based on percentActive of each allowable value
                const random = Math.floor(Math.random() * 100);
                let accumulator = 0;
                let initialValue = feature.allowedValues.find(allowedValue => {
                    const lowerBound = accumulator;
                    const upperBound = accumulator + allowedValue.percentActive;
                    accumulator = upperBound;
                    return (random >= lowerBound && random < upperBound);
                });
                return {key: feature.key, value: initialValue.value};
            });
            this.setFeatureValues(initialValues);
        },
        setFeatureValues (data) {
            this.featureValues = data;
            this.featureValues.forEach(({key, value}) => {
                useCookie(key).value = value;
            });
        },
        setFeatureValue ({key, value}) {
            useCookie(key).value = value;
            const featureValues = this.featureValues.filter(featureValue => featureValue.key !== key);
            featureValues.push({key, value});
            this.featureValues = featureValues;
        },
        resetFeatureValue (key) {
            useCookie(key).value = null;
            window.location.reload();
        },
        resetAllFeatureValues () {
            this.features.forEach(feature => {
                useCookie(feature.key).value = null;
            });
            window.location.reload();
        }
    },
    getters: {
        getFeatureValue () {
            return (key) => {
                return this.featureValues.find(featureValue => featureValue.key === key)?.value;
            };
        },
    },
});
