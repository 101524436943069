export default function () {

    function build (entity) {
        if (!entity) throw Error('No supplied entity.');
        return {
            ...entity,
            session_status: sessionStatus(entity),
            session_ip_url: sessionIpUrl(entity),
            session_accessed_time_ago: sessionAccessTimeAgo(entity),
        };
    }

    async function close (idSession) {
        let error = null;
        try {
            await useApi('auth').delete('me/sessions/' + idSession);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    const UtilityDates = useUtilityDates();

    function sessionStatus (entity) {
        return !entity.session_deleted ? 'Open Session' : 'Closed Session';
    }

    function sessionIpUrl (entity) {
        if (!entity.session_ip) return undefined;
        return 'https://ipinfo.io/' + entity.session_ip;
    }

    function sessionAccessTimeAgo (entity) {
        return entity.session_accessed > new Date() ? UtilityDates.printDateTimeAgo(new Date()) : UtilityDates.printDateTimeAgo(entity.session_accessed);
    }

    return {build, close};
}
