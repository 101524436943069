<template>
    <div class="article-answer-app-password">
        <p>
            For the best experience at this time, manage your account details on our website, which will reset it in the
            app too as the accounts are linked.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerAppPassword',
};
</script>
