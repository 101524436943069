export default function () {

    const statusFlags = {
        unknown: null,
        loggedIn: true,
        loggedOut: false,
    };

    function build (entity) {
        if (!entity) throw Error('No supplied entity.');
        return {
            ...entity,
            full_name: fullName(entity),
        };
    }

    const ApiCancel = useApiCancel();

    async function get (cancelKey) {
        console.log('Getting current user...');
        let currentUser = null, isLoggedIn = null, error = null;
        const signal = ApiCancel.supersede(cancelKey);
        try {
            const {data} = await useApi('auth').get('me', null, signal);
            ApiCancel.remove(cancelKey);
            currentUser = build(data.user);
            isLoggedIn = currentUser?.id_user ? statusFlags.loggedIn : statusFlags.loggedOut;
        } catch (e) {
            error = useApiError(e);
            if (error) console.error(error);
            isLoggedIn = statusFlags.unknown;
        }
        return {currentUser, isLoggedIn, error};
    }

    async function post (params) {
        let user = null, error = null;
        try {
            const {data} = await useApi('auth').post('me', params);
            user = build(data.user);
        } catch (e) {
            error = useApiError(e);
        }
        return {user, error};
    }

    async function patch (params) {
        let error = null;
        try {
            await useApi('auth').patch('me', params);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    async function logout () {
        let error = null;
        try {
            await useApi('auth').post('me/logout');
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    async function deleteMe () {
        let error = null;
        try {
            await useApi('auth').delete('me');
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    async function putWelcomeCleared () {
        let error = null;
        try {
            await useApi('auth').put('me/welcome');
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    async function putNotificationsMailers (params) {
        let error = null;
        try {
            await useApi('auth').put('me/notifications-mailers', params);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    const UtilityFunctions = useUtilityFunctions();

    function fullName (entity) {
        if (!entity.first_name || !entity.last_name) return undefined;
        return UtilityFunctions.capitalizeFirstLetter(entity.first_name) + ' ' + UtilityFunctions.capitalizeFirstLetter(entity.last_name);
    }

    return {statusFlags, build, get, post, patch, logout, deleteMe, putWelcomeCleared, putNotificationsMailers};
};
