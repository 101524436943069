<template>
    <div class="article-why-download-the-app">
        <ul class="list-outside">
            <li>Free to download on all android devices</li>
            <li>Find ALL Andy's video lessons and courses in one place</li>
            <li>Syncs with your website account to access Andy's content anywhere</li>
            <li>Premium lessons include interactive TAB, which you can jam along with!</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleWhyDownloadTheApp',
};
</script>
