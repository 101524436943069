export default function () {

    async function get (filter) {
        let parties = null, error = null;
        try {
            const {data} = await useApi('finance').get('me/parties', filter);
            parties = data.parties;
        } catch (e) {
            error = useApiError(e);
        }
        return {parties, error};
    }

    return {get};
}
