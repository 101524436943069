<template>
    <div class="article-answer-included-aaa">
        <p>
            The AAA membership unlocks all premium lessons, courses, content and features. It unlocks all 'locked' videos
            and courses. Many lessons and courses include interactive TAB synced to video, which is a feature exclusive
            to the membership.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerIncludedAaa',
};
</script>
