export default function () {

    const EntityCourse = useEntityCourse();

    async function get(filter) {
        let courses = null, error = null;
        try {
            const {data} = await useApi('lessons').get('courses', filter);
            courses = data.courses.map((course) => {
                return EntityCourse.build(course);
            });
        } catch (e) {
            error = useApiError(e);
        }
        return {courses, error};
    }

    return {get};
}
