<template>
    <div class="article-why-no-ios-app">
        <p>
            We no longer support an official Andy Guitar app on iOS.
            However, we've made improvements to the Andy Guitar website, It now works the same as an app - but better!
        </p>
        <p>
            This will provide the exact same experience as an app on mobile or iPad! This also gives you the bonus features
            such as progress tracking, making it a far better experience than our original iOS app.
            Give it a try!
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleWhyNoIosApp',
};
</script>
