<template>
    <div class="article-answer-app-videos">
        <p>
            There is a known issue with playing some videos on the iOS app. Unfortunately, we can’t get a fix out at this
            time. This is due to policy changes made by Apple. Please try using the website in the meantime as it has
            all the same content, more features and is updated more often.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerAppVideos',
};
</script>
