export const useStoreThreadsUnread = defineStore('StoreThreadsUnread', {
    state: () => ({
        unreadCount: null,
        poller: null,
        paused: null
    }),
    actions: {
        stopUnreadThreadPoller () {
            this.paused = true;
        },
        startUnreadThreadPoller () {
            if (!import.meta.client || !useStoreAuth().isGuest) return;
            this.paused = false;
            if (!this.poller) {
                this.getUnreadThreads();
                this.pollUnreadThreads();
            }
        },
        pollUnreadThreads () {
            if (this.poller) return false;
            this.poller = setInterval(() => {
                this.getUnreadThreads();
            }, 5000);
        },
        async getUnreadThreads () {
            const storeAuth = useStoreAuth();
            if (!storeAuth.isLoggedIn || this.paused) return false;
            ({count: this.unreadCount, error: this.error} = await useQueryThreadsUnread().get());
        },
        reset () {
            this.paused = true;
            this.unreadCount = null;
        },
    },
});
