export default function () {

    async function post (idSubscription, params) {
        let paymentMethod = null, error = null;
        try {
            const {data} = await useApi('finance').post('me/subscriptions/' + idSubscription + '/methods', params);
            paymentMethod = data.payment_method;
        } catch (e) {
            error = useApiError(e);
        }
        return {paymentMethod, error};
    }

    async function remove (idSubscription, idStripePaymentMethod) {
        let error = null;
        try {
            await useApi('finance').delete('me/subscriptions/' + idSubscription + '/methods/' + idStripePaymentMethod);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    return {post, remove};
}
