function delay (t, v) {
    return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t);
    });
}

export const useStoreSubscriptionAdd = defineStore('StoreSubscriptionAdd', {
    state: () => ({
        subscription: null,
        address: null,
        party: null,
        paymentError: null,
        disabled: null,
        error: null,
    }),
    actions: {
        init: function (idSubscriptionPlan) {
            if (this.subscription && parseInt(this.subscription.fk_plan_subscription) !== parseInt(idSubscriptionPlan)) {
                this.reset();
            }
            this.getAddress();
        },
        getAddress: async function () {
            this.error = null;
            let filter = {
                fields: {
                    fk_organisation_party: null,
                },
            };
            const {parties, error} = await useQueryParties().get(filter);
            if (error) {
                return this.error = error;
            }
            if (parties.length === 0) {
                return this.error = 'Something went wrong handling your address, please contact support.';
            }
            this.party = parties[0];
            ({address: this.address, error: this.error} = await useEntityParty().getAddress(this.party.id_party));
        },
        updateAddressField ({field, value}) {
            if (this.address[field] === undefined) throw new Error('Attempted to update an address field which does not exist (' + field + ').');
            this.address[field] = value;
        },
        postAddress: async function () {
            this.error = null;
            this.disabled = true;
            ({address: this.address, error: this.error} = await useEntityParty().postAddress(this.party.id_party, this.address));
            this.disabled = false;
        },
        getSubscription: async function (idSubscription) {
            if (this.subscription && this.subscription.id_subscription !== parseInt(idSubscription)) {
                this.subscription = null;
            }
            this.error = null;
            ({subscription: this.subscription, error: this.error} = await useEntitySubscription().get(idSubscription));
        },
        awaitSubscriptionActive: async function () {
            if (!this.subscription || !this.subscription.id_subscription) return false;
            this.error = null;
            const StoreAuth = useStoreAuth();
            await StoreAuth.getCurrentUser();
            if (!StoreAuth.currentUser?.has_aaa_membership) {
                return delay(1000)
                    .then(() => {
                        return this.awaitSubscriptionActive();
                    });
            } else {
                // This method call changes membership page from checkout mode to status mode on success
                return useStoreMembership().getMembership()
                    .then(() => {
                        return Promise.all([
                            useStoreLesson().refreshLesson(),
                        ]);
                    })
                    .then(() => {
                        this.trackGtagConversion();
                        useEntityAction().postActionSubscriptionCreated();
                    });
            }
        },
        postSubscription: async function ({idSubscriptionPlan, paymentMethod}) {
            this.error = null;
            this.paymentError = null;
            this.disabled = true;
            let params = {
                id_subscription_plan: idSubscriptionPlan,
                stripe_payment_method_id: paymentMethod.id
            };
            ({subscription: this.subscription, error: this.paymentError} = await useEntitySubscription().post(params));
            if (this.paymentError) {
                this.disabled = false;
                throw this.paymentError;
            }
            await this.getSubscription(this.subscription.id_subscription);
            this.disabled = false;
        },
        trackGtagConversion: function () {
            const gtag = useGtag();
            gtag.gtag('event', 'conversion', {'send_to': 'AW-10969725497/AeX8CJ2NgYUYELn04u4o'});
        },
        clearError: function () {
            this.error = null;
            this.paymentError = null;
        },
        reset: function () {
            this.subscription = null;
            this.address = null;
            this.party = null;
            this.paymentError = null;
            this.disabled = null;
            this.error = null;
        },
    },
});
