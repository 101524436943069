export default defineNuxtPlugin(nuxt => {
    const returnRoute = {};
    const $router = useRouter();

    let debug = false;

    function isAuthRoute (routePath) {
        if (debug) console.log('Is auth route?', routePath);
        if (routePath.startsWith('/signup')) return true;
        return !!routePath.startsWith('/login');
    }

    function init () {
        let route = useCookieReturn().get();
        if (debug) console.log('Init return route', route);
        returnRoute.route = route;
    }

    init();

    returnRoute.set = function (route) {
        if (isAuthRoute(route.path)) return false;
        returnRoute.route = {
            path: route.path,
            name: route.name,
            params: route.params,
            query: route.query,
        };
        useCookieReturn().set(returnRoute.route);
        if (debug) console.log('Set return route', returnRoute.route);
        return returnRoute.route;
    };

    returnRoute.clear = function () {
        if (debug) console.log('Clearing return route');
        useCookieReturn().remove();
        returnRoute.route = null;
    };

    returnRoute.use = function () {
        let route = returnRoute.route || '/';
        if (debug) console.log('Using return route', route);
        returnRoute.clear();
        return $router.replace(route).catch(() => {});
    };

    nuxt.provide('returnRoute', returnRoute);
});
