function delay (t, v) {
    return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t);
    });
}

export const useStoreSubscription = defineStore('StoreSubscription', {
    state: () => ({
        subscription: null,
        paymentMethods: null,
        invoices: null,
        paymentError: null,
        disabled: null,
        error: null,
        errorDeletePaymentMethod: null,
    }),
    actions: {
        getSubscription: async function (idSubscription) {
            if (this.subscription && this.subscription.id_subscription !== parseInt(idSubscription)) {
                this.subscription = null;
                this.paymentMethods = null;
                this.invoices = null;
            }
            this.error = null;
            this.errorDeletePaymentMethod = null;
            ({subscription: this.subscription, error: this.error} = await useEntitySubscription().get(idSubscription));
            if (this.error) return;
            await this.getPaymentMethods();
            await this.getInvoices();
        },
        awaitSubscriptionActive: async function () {
            if (!this.subscription || !this.subscription.id_subscription) return false;
            const StoreAuth = useStoreAuth();
            this.error = null;
            const {subscription, error} = await useEntitySubscription().get(this.subscription.id_subscription);
            this.error = error;
            if (subscription?.subscription_status !== 'active') {
                return delay(1000)
                    .then(() => {
                        return this.awaitSubscriptionActive();
                    });
            } else {
                await StoreAuth.getCurrentUser();
                this.subscription = subscription;
                await this.getPaymentMethods();
                await this.getInvoices();
            }
        },
        putSubscriptionCancelled: async function () {
            this.error = null;
            this.errorDeletePaymentMethod = null;
            this.disabled = true;
            ({error: this.error} = await useEntitySubscription().cancel(this.subscription.id_subscription));
            this.disabled = false;
            if (!this.error) {
                this.getSubscription(this.subscription.id_subscription).finally();
                useStoreSubscriptions().getSubscriptions().finally();
                return useStoreMembership().reset();
            }
            this.disabled = false;
        },
        getPaymentMethods: async function () {
            if (!this.subscription || !this.subscription.id_subscription) return false;
            this.error = null;
            this.errorDeletePaymentMethod = null;
            let filter = {
                order: [
                    {field: 'stripe_payment_method_created', asc: false},
                ],
            };
            ({paymentMethods: this.paymentMethods, error: this.error} = await useQueryPaymentMethods().get(this.subscription.id_subscription, filter));
        },
        postPaymentMethod: async function (paymentMethod) {
            this.error = null;
            this.paymentError = null;
            this.errorDeletePaymentMethod = null;
            this.disabled = true;
            let params = {
                stripe_payment_method_id: paymentMethod.id
            };
            ({error: this.error} = await useEntityPaymentMethod().post(this.subscription.id_subscription, params));
            if (this.error) {
                this.disabled = false;
                throw this.error;
            }
            await this.getSubscription(this.subscription.id_subscription);
            this.disabled = false;
        },
        deletePaymentMethod: async function (paymentMethod) {
            this.error = null;
            this.errorDeletePaymentMethod = null;
            this.disabled = true;
            ({error: this.errorDeletePaymentMethod} = await useEntityPaymentMethod().remove(this.subscription.id_subscription, paymentMethod.id_stripe_payment_method));
            if (this.errorDeletePaymentMethod) {
                return this.disabled = false;
            }
            await this.getSubscription(this.subscription.id_subscription);
            this.disabled = false;
        },
        getInvoices: async function () {
            if (!this.subscription || !this.subscription.id_subscription) return false;
            this.error = null;
            let filter = {
                paginate: {
                    auto: false
                },
                fields: {
                    stripe_invoice_total: {
                        min: '0.01'
                    },
                },
                order: [
                    {field: 'stripe_invoice_created', asc: false}
                ],
            };
            ({invoices: this.invoices, error: this.error} = await useQueryInvoices().get(this.subscription.id_subscription, filter));
        },
        reset: function () {
            this.subscription = null;
            this.paymentMethods = null;
            this.invoices = null;
            this.paymentError = null;
            this.disabled = null;
            this.error = null;
            this.errorDeletePaymentMethod = null;
        },
    },
    getters: {
        cancellationQuestion () {
            return this.subscription && this.subscription.subscription_product_cancellation_question;
        },
    },
});
