<template>
    <div class="article-answer-download-ebooks">
        <p>
            On both the website or Android app you will find a link to the relevant eBook on the lesson page under each video.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerDownloadEbooks',
};
</script>
