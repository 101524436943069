<template>
    <div class="article-cookies-video">
        <p>
            Video cookies enable videos embedded in the website to play, and may involve third party cookies which may
            track information about you.
        </p>
        <ul>
            <li>
                <strong>YouTube Cookies</strong>
                - YouTube use multiple cookies to collect or receive information about you when you play an embedded
                YouTube video on our website.
                YouTube is a service provided by Google and their privacy policy is available at: <a
                    href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
                (cookies used for this purpose are: Multiple YouTube Cookies).
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleCookiesVideo',
};
</script>
