export const useStoreUnsubscribes = defineStore('StoreUnsubscribes', {
    state: () => ({
        success: null,
        error: null,
    }),
    actions: {
        async unsubscribe ({type, email, token}) {
            let method;
            if (type === 'normal') method = useEntityUnsubscribe().unsubscribeEmails;
            if (type === 'mailers') method = useEntityUnsubscribe().unsubscribeMailers;
            ({success: this.success, error: this.error} = await method(email, token));
        },
        reset () {
            this.success = null;
            this.error = null;
        },
    },
});
