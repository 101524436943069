export default function (e) {
    if (!e) {
        // Covers developer error using this without an actual error
        console.warn('The composable useApiError should be passed an error.');
        return e;
    }
    if (e.alert === 'Request cancelled.') return null;
    if (e.alert) return e.alert;
    console.error(e);
    return 'Something is not quite right, please try again or contact support.';
};
