// A utility to make requests using the native fetch api, with some axios-like error handling

export default defineNuxtPlugin (nuxt => {
    const customFetch = function (url, init) {
        return fetch(url, init)
            .then(response => {
                if (response.headers.get('content-type').includes('application/json')) {
                    return response.json()
                        .then(data => {
                            response.data = data;
                            return response;
                        });
                } else {
                    return response;
                }
            })
            .then(response => {
                if (!response.ok) {
                    const error = new Error('Network error');
                    error.response = response;
                    throw error;
                }
                return response;
            });
    };

    nuxt.provide('customFetch', customFetch);
});
