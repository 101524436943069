<template>
    <div class="article-live-description">
        <p>
            Join me here on the website for weekly live sessions where we can work directly on your playing & give the
            feedback needed for you to move forward and get inspired!
        </p>
        <p>
            Every week we cover topics such as:
        </p>
        <ul>
            <li>How to make the most of your practice time</li>
            <li>Songs and exercises at your level and based on your music taste</li>
            <li>How you can stay motivated and maximise your progress</li>
        </ul>
        <p>
            You can also request a tutorial for a song YOU want to learn and Andy will teach it during the session live!
            Each live stream is automatically available as a replay so you can catch up anytime and never miss a beat!
        </p>
        <p class="font-semibold" v-if="!!currentUser.has_aaa_membership">
            Connect with Andy and other AAA members between live sessions with the Andy Guitar AAA Discord community
            - the link is on the membership page
            <NuxtLink :to="currentUser?.aaa_community_discord_link">here</NuxtLink>.
        </p>
    </div>
</template>

<script>
import { mapState } from 'pinia';

export default {
    name: 'ArticleLiveDescription',
    computed: {
        ...mapState(useStoreAuth, {
            currentUser: 'currentUser',
        }),
    },
};
</script>
