<template>
    <div class="article-answer-what-andy">
        <p>
            Andy Guitar is a guitar tutorial website where you will find thousands of <strong>exclusive</strong> video guitar lessons by Andy Crowley. With over 2.7 million subscribers and 310m+ views on Youtube he is one of the most
            prominent guitar teachers online.
        </p>
        <p>
            Andy has a large collection of online courses catering for every level from beginner to advanced. There is also a huge catalogue of lessons teaching you guitar parts to popular songs. Additionally, You will find guest teacher courses
            hosted by other top music tutors. These courses cover a range of topics including performance techniques, singing and deep dives into specific styles of guitar.
        </p>
        <p>
            Try now with a 10-day free trial of Andy's <NuxtLink to="/membership">Access All Areas Membership.</NuxtLink>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerWhatAndy',
};
</script>
