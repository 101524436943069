<template>
    <div class="article-answer-talk-andy">
        <p>
            Andy hosts weekly live streams for AAA members where you can talk to Andy and engage with other members.
            There is also a thriving Discord community in which Andy is actively involved.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerTalkAndy',
};
</script>
