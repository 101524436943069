<template>
    <NuxtPicture
        v-if="$features.optimizeImages()"
        :imgAttrs="{...$attrs, src: transformedSrc}"
        :src="transformedSrc"
        :placeholder="placeholder"
        :format="format"
        :quality="quality"
        :sizes="sizes"
    />
    <img
        v-else
        v-bind="$attrs"
        :src="transformedSrc"
        :alt="$attrs.alt"
    />
</template>

<script>
export default {
    name: 'ImageOptimized',
    inheritAttrs: false,
    props: {
        placeholder: {
            required: false,
        },
        sizes: {
            required: false,
        },
        quality: {
            required: false,
        },
        format: {
            required: false,
            default: 'webp,avif,jpg'
        },
        src: {
            required: false,
        },
    },
    computed: {
        transformedSrc () {
            if (this.src.startsWith('/')) {
                return this.$config.public.base + this.src;
            }
            return this.src;
        }
    },
};
</script>
