export default function () {

    const EntityCourse = useEntityCourse();

    async function get (filter) {
        let pathways = null, error = null;
        try {
            const {data} = await useApi('lessons').get('pathways', filter);
            pathways = data.pathways;
            pathways.forEach((pathway) => {
                pathway.pathway_courses = pathway?.pathway_courses.map((course) => {
                    return EntityCourse.build(course);
                });
            });
        } catch (e) {
            error = useApiError(e);
        }
        return {pathways, error};
    }

    return {get};
}
