let debounceTimeout = {
    seekNativeVideo: null,
    songSearch: null,
    lessonsSearch: null,
};

export default function () {

    const $config = useRuntimeConfig().public;
    const storeFeatures = useStoreFeatures();

    const ApiAnalytics = useApi('analytics');
    const methods = {};

    async function postAction (actionTypeName, values) {
        if (import.meta.server) return {error: null}; // Don't run actions server-side
        let error = null;
        if (!values) values = [];
        if (!$config.actionsEnabled) return null;
        let params = {
            action_type_name: actionTypeName,
        };
        let features = '';
        storeFeatures.featureValues.forEach(feature => {
            features = features + `${feature.key}=${feature.value};`;
        });
        values.push({key: 'features', value: features, type: 'S'});
        if (values && values.length) {
            params.action_values = JSON.stringify(values);
        }
        try {
            await ApiAnalytics.post('actions', params);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    methods.postActionSubscriptionCreated = function () {
        return postAction('Subscription Created');
    };

    /**
     * @param {String} question
     * @param {String} answer
     * @param {String} userFeedbackText
     */
    methods.postActionSubscriptionCancellation = function (question, answer, userFeedbackText) {
        let subscriptionCancellationUserData = [
            {key: 'cancellation_question', value: question, type: 'S'},
            {key: 'cancellation_answer', value: answer, type: 'S'},
        ];
        if (userFeedbackText) subscriptionCancellationUserData.push({
            key: 'cancellation_feedback',
            value: userFeedbackText,
            type: 'S'
        });
        return postAction('Subscription Cancellation', subscriptionCancellationUserData);
    };

    methods.postActionAnnounceLiveStreamPresence = function () {
        return postAction('Announce Live Stream Presence');
    };

    methods.postActionNotFound = function (slug) {
        return postAction('Not Found', [
            {key: 'slug', value: slug, type: 'S'},
        ]);
    };

    methods.postActionPageLoad = function (slug) {
        return postAction('Page Load', [
            {key: 'slug', value: slug, type: 'S'},
        ]);
    };

    methods.postActionTrackedPageLoad = function (params) {
        return postAction('Tracked Page Load', params.map(param => ({
            key: param.key,
            value: param.value,
            type: 'S',
        })));
    };

    methods.postActionForm = function (actionTypeName, formRef, routeName, formError) {
        let values = [
            {key: 'form_ref', value: formRef, type: 'S'}
        ];
        if (routeName) values.push({key: 'route_name', value: routeName, type: 'S'});
        if (formError) values.push({key: 'form_error', value: formError, type: 'S'});
        return postAction(actionTypeName, values);
    };

    methods.postActionSelectAAAPlan = function (planName) {
        return postAction('Select AAA Plan', [
            {key: 'plan_name', value: planName, type: 'S'},
        ]);
    };

    methods.postActionSongSearch = function (order, query, filters) {
        let values = [];
        if (order) values.push({type: 'S', key: 'order', value: order});
        if (query) values.push({type: 'S', key: 'query', value: query});
        const mappedFilters = Object.entries(filters)
            .map(([key, value]) => {
                return {
                    type: 'S',
                    key: 'filter',
                    value: `${key}=${value.strings.join(',')}`
                };
            });
        values = values.concat(mappedFilters);
        if (debounceTimeout.songSearch) clearTimeout(debounceTimeout.songSearch);
        debounceTimeout.songSearch = setTimeout(() => {
            return postAction('Song Search', values);
        }, 5000);
    };

    methods.postActionPlaySoundslice = function (slug) {
        return postAction('Play Soundslice', [
            {key: 'slug', value: slug, type: 'S'},
        ]);
    };

    methods.postActionViewLesson = function (lessonSlug, lessonLocked) {
        const values = [
            {key: 'lesson_slug', value: lessonSlug, type: 'S'}
        ];
        if (lessonLocked !== undefined && lessonLocked !== null) values.push({
            key: 'lesson_locked',
            value: lessonLocked ? 1 : 0,
            type: 'B'
        });
        return postAction('View Lesson', values);
    };

    methods.postActionChooseFreePlan = function () {
        return postAction('Choose Free Plan');
    };

    methods.postActionChoosePaidPlan = function () {
        return postAction('Choose Paid Plan');
    };

    return methods;
};
