<template>
    <div class="article-membership-selling-points">
        <div role="list" class="mt-8 space-y-3 text-white xl:mt-10" :class="textSize">
            <div
                class="flex flex-row items-start space-x-2"
                role="listitem"
                v-for="(sellingPoint, index) in sellingPoints" :key="'selling-point-' + index"
            >
                <div>
                    <IconCheck></IconCheck>
                </div>
                <div>
                    {{ sellingPoint }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticleMembershipSellingPoints',
    props: {
        textSize: {
            required: false,
            type: String,
            default: 'text-lg'
        },
    },
    computed: {
        sellingPoints () {
            return [
                'Full access to all courses',
                'Weekly Live Q&A with Andy',
                'Discord community access',
                'Interactive TAB',
            ];
        },
    },
};
</script>
