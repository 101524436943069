export default function () {

    async function unsubscribeEmails (email, token) {
        let success = null, error = null;
        try {
            const {data} = await useApi('auth').post('me/unsubscribes', {email, token});
            success = data.success;
        } catch (e) {
            error = useApiError(e);
        }
        return {success, error};
    }

    async function unsubscribeMailers (email, token) {
        let success = null, error = null;
        try {
            const {data} = await useApi('auth').post('me/unsubscribes/mailers', {email, token});
            success = data.success;
        } catch (e) {
            error = useApiError(e);
        }
        return {success, error};
    }

    return {unsubscribeEmails, unsubscribeMailers};
};
