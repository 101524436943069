<template>
    <div class="article-privacy-policy text-base leading-8">
        <section>
            <ol class="sections">
                <li>
                    Introduction
                    <ol>
                        <li>
                            We are committed to safeguarding the privacy of our website visitors and
                            service users.
                        </li>
                        <li>
                            This policy applies where we are acting as a data controller with respect
                            to the personal data of our website visitors and service users; in other words, where we determine
                            the purposes and means of the processing of that personal data.
                        </li>
                        <li>
                            We use cookies on our website. Insofar as those cookies are not strictly
                            necessary for the provision of our website and services, we will ask you to consent to our use of
                            cookies when you first visit our website.
                        </li>
                        <li>
                            In this policy, "we", "us" and "our" refer to {{ $config.public.companyNameLong }}. For more
                            information about us, see Section 17.
                        </li>
                    </ol>
                </li>
                <li class="section ">
                    How we use your personal data
                    <ol>
                        <li>
                            In this Section 2 we have set out:
                            <ol>
                                <li>the general categories of personal data that we may process;</li>
                                <li>the purposes for which we may process personal data; and</li>
                                <li>the legal bases of the processing.</li>
                            </ol>
                        </li>
                        <li>
                            How we use your personal data depends on your relationship with us:
                            <ol>
                                <li>
                                    <strong>Website Account Holder</strong> - any user of the website who has an account
                                    on the website.
                                </li>
                                <li>
                                    <strong>Website Visitor</strong> - any user of the website who does not have an
                                    account, or has an account but is not logged in.
                                </li>
                            </ol>
                        </li>
                        <li v-for="policy in policies" v-bind:key="policy.id">
                            We may process the <strong>{{ policy.group }}</strong> of a <strong>{{ policy.subject }}</strong>
                            - namely <span>{{ policy.group_description }}</span>
                            <br /><span class="legal-basis">The legal basis for this processing is <em>{{ policy.legal_basis }}</em>.</span>
                        </li>
                        <li>
                            We may process any of your personal data identified in this policy where
                            necessary for the establishment, exercise or defence of legal claims, whether in court proceedings
                            or in an administrative or out-of-court procedure. The legal basis for this processing is our
                            legitimate interests, namely the protection and assertion of our legal rights, your legal rights and
                            the legal rights of others.
                        </li>
                        <li>
                            In addition to the specific purposes for which we may process your
                            personal data set out in this Section 2, we may also process any of your personal data where such
                            processing is necessary for compliance with a legal obligation to which we are subject, or in order
                            to protect your vital interests or the vital interests of another natural person.
                        </li>
                        <li>
                            Please do not supply any other person's personal data to us, unless we
                            prompt you to do so.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Providing your personal data to others
                    <ol class="mb-3 ">
                        <li>
                            In addition to the specific disclosures of personal data set out in this
                            Section 3, we may disclose your personal data where such disclosure is necessary for compliance with
                            a legal obligation to which we are subject, or in order to protect your vital interests or the vital
                            interests of another natural person. We may also disclose your personal data where such disclosure
                            is necessary for the establishment, exercise or defence of legal claims, whether in court
                            proceedings or in an administrative or out-of-court procedure.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    International transfers of your personal data
                    <ol class="mb -3 ">
                        <li>
                            In this Section 4, we provide information about the circumstances in which
                            your personal data may be transferred to countries outside the European Economic Area (EEA).
                        </li>
                        <li>
                            The hosting facilities for our website are situated in Ireland
                        </li>
                        <li>
                            You acknowledge that personal data that you submit for publication through
                            our website or services may be available, via the internet, around the world. We cannot prevent the
                            use (or misuse) of such personal data by others.
                        </li>
                        <li>
                            We use Amazon Web Services CloudFront CDN to cache data on servers local to users around the world
                            so that the cached resources load quickly. This means that personal data you provide to us in the form of images
                            and other static assets, may be stored for a short time on servers in countries outside of the European Economic Area (EEA).
                            Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data
                            protection clauses adopted or approved by the European Commission.  Amazon Web Services are fully compliant with the
                            GDPR - <a href="https://aws.amazon.com/compliance/gdpr-center/">https://aws.amazon.com/compliance/gdpr-center/</a>.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Retaining and deleting personal data
                    <ol class="mb-3 ">
                        <li>
                            This Section 5 sets out our data retention policies and procedure, which
                            are designed to help ensure that we comply with our legal obligations in relation to the retention
                            and deletion of personal data.
                        </li>
                        <li>
                            Personal data that we process for any purpose or purposes shall not be
                            kept for longer than is necessary for that purpose or those purposes.
                        </li>
                        <li>
                            We will retain your personal data as follows:
                            <ol class="mb-3">
                                <li v-for="policy in policies" v-bind:key="policy.id">
                                    The <strong>{{ policy.group }}</strong> of a <strong>{{ policy.subject }}</strong> {{ policy.expiry_readable }}.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Not withstanding the other provisions of this Section 5, we may retain your
                            personal data where such retention is necessary for compliance with a legal obligation to which we
                            are subject, or in order to protect your vital interests or the vital interests of another natural
                            person.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Security of personal data
                    <ol class="mb-3 ">
                        <li>
                            We will take appropriate technical and organisational precautions to
                            secure your personal data and to prevent the loss, misuse or alteration of your personal data.
                        </li>
                        <li>
                            We will store all your personal data on secure servers, personal computers
                            and mobile devices, and in secure manual record-keeping systems.
                        </li>
                        <li>
                            The following personal data will be stored by us in encrypted form:
                            password(s).
                        </li>
                        <li>
                            Data relating to browsing requests that is sent from your web browser to
                            our web server, or from our web server to your web browser, will be protected using encryption
                            technology.
                        </li>
                        <li>
                            You acknowledge that the transmission of unencrypted (or inadequately
                            encrypted) data over the internet is inherently insecure, and we cannot guarantee the security of
                            data sent over the internet.
                        </li>
                        <li>
                            You should ensure that your password is not susceptible to being guessed,
                            whether by a person or a computer program. You are responsible for keeping the password you use for
                            accessing our website confidential and we will not ask you for your password (except when you log in
                            to our website).
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Amendments
                    <ol class="mb-3 ">
                        <li>
                            We may update this policy from time to time by publishing a new version on
                            our website.
                        </li>
                        <li>
                            You should check this page occasionally to ensure you are happy with any
                            changes to this policy.
                        </li>
                        <li>
                            We may notify you of significant changes to this policy by email or
                            through the private messaging system on our website.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Your rights
                    <ol class="mb-3 ">
                        <li>
                            In this Section 8, we have summarised the rights that you have under data
                            protection law. Some of the rights are complex, and not all of the details have been included in our
                            summaries. Accordingly, you should read the relevant laws and guidance from the regulatory
                            authorities for a full explanation of these rights.
                        </li>
                        <li>
                            Your principal rights under data protection law are:
                            <ol class="mb-3">
                                <li>the right to access;</li>
                                <li>the right to rectification;</li>
                                <li>the right to erasure;</li>
                                <li>the right to restrict processing;</li>
                                <li>the right to object to processing;</li>
                                <li>the right to data portability;</li>
                                <li>the right to complain to a supervisory authority; and</li>
                                <li>the right to withdraw consent.</li>
                            </ol>
                        </li>
                        <li>
                            You have the right to confirmation as to whether or not we process your
                            personal data and, where we do, access to the personal data, together with certain additional
                            information. That additional information includes details of the purposes of the processing, the
                            categories of personal data concerned and the recipients of the personal data. Providing the rights
                            and freedoms of others are not affected, we will supply to you a copy of your personal data. The
                            first copy will be provided free of charge, but additional copies may be subject to a reasonable
                            fee.
                        </li>
                        <li>
                            You have the right to have any inaccurate personal data about you
                            rectified and, taking into account the purposes of the processing, to have any incomplete personal
                            data about you completed.
                        </li>
                        <li>
                            In some circumstances you have the right to the erasure of your personal
                            data without undue delay. Those circumstances include: the personal data are no longer necessary in
                            relation to the purposes for which they were collected or otherwise processed; you withdraw consent
                            to consent-based processing; you object to the processing under certain rules of applicable data
                            protection law; the processing is for direct marketing purposes; and the personal data have been
                            unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions
                            include where processing is necessary: for exercising the right of freedom of expression and
                            information; for compliance with a legal obligation; or for the establishment, exercise or defence
                            of legal claims.
                        </li>
                        <li>
                            In some circumstances you have the right to restrict the processing of
                            your personal data. Those circumstances are: you contest the accuracy of the personal data;
                            processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes
                            of our processing, but you require personal data for the establishment, exercise or defence of legal
                            claims; and you have objected to processing, pending the verification of that objection. Where
                            processing has been restricted on this basis, we may continue to store your personal data. However,
                            we will only otherwise process it: with your consent; for the establishment, exercise or defence of
                            legal claims; for the protection of the rights of another natural or legal person; or for reasons of
                            important public interest.
                        </li>
                        <li>
                            You have the right to object to our processing of your personal data on
                            grounds relating to your particular situation, but only to the extent that the legal basis for the
                            processing is that the processing is necessary for: the performance of a task carried out in the
                            public interest or in the exercise of any official authority vested in us; or the purposes of the
                            legitimate interests pursued by us or by a third party. If you make such an objection, we will cease
                            to process the personal information unless we can demonstrate compelling legitimate grounds for the
                            processing which override your interests, rights and freedoms, or the processing is for the
                            establishment, exercise or defence of legal claims.
                        </li>
                        <li>
                            You have the right to object to our processing of your personal data for
                            direct marketing purposes (including profiling for direct marketing purposes). If you make such an
                            objection, we will cease to process your personal data for this purpose.
                        </li>
                        <li>
                            You have the right to object to our processing of your personal data for
                            scientific or historical research purposes or statistical purposes on grounds relating to your
                            particular situation, unless the processing is necessary for the performance of a task carried out
                            for reasons of public interest.
                        </li>
                        <li>
                            To the extent that the legal basis for our processing of your personal
                            data is:
                            <ol class="mb-3">
                                <li>consent; or</li>
                                <li>
                                    that the processing is necessary for the performance of a contract to which you are party or
                                    in order to take steps at your request prior to entering into a contract,
                                </li>
                            </ol>
                            and such processing is carried out by automated means, you have the right to receive your personal
                            data from us in a structured, commonly used and machine-readable format. However, this right does
                            not apply where it would adversely affect the rights and freedoms of others.
                        </li>
                        <li>
                            If you consider that our processing of your personal information infringes
                            data protection laws, you have a legal right to lodge a complaint with a supervisory authority
                            responsible for data protection. You may do so in the EU member state of your habitual residence,
                            your place of work or the place of the alleged infringement.
                        </li>
                        <li>
                            To the extent that the legal basis for our processing of your personal
                            information is consent, you have the right to withdraw that consent at any time. Withdrawal will not
                            affect the lawfulness of processing before the withdrawal.
                        </li>
                        <li>
                            You may exercise any of your rights in relation to your personal data by
                            email, in writing, or using our website contact form.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Third party websites
                    <ol class="mb-3 ">
                        <li>
                            Our website includes hyperlinks to, and details of, third party
                            websites.
                        </li>
                        <li>
                            We have no control over, and are not responsible for, the privacy policies
                            and practices of third parties.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Personal data of children
                    <ol class="mb-3 ">
                        <li>Our website and services are targeted at persons over the age of 18.</li>
                        <li>
                            If we have reason to believe that we hold personal data of a person under
                            that age in our databases, we will delete that personal data.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Updating information
                    <ol class="mb-3 ">
                        <li>
                            Please let us know if the personal information that we hold about you
                            needs to be corrected or updated.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    About cookies
                    <ol class="mb-3 ">
                        <li>
                            A cookie is a file containing an identifier (a string of letters and
                            numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier
                            is then sent back to the server each time the browser requests a page from the server.
                        </li>
                        <li>
                            Cookies may be either "persistent" cookies or "session" cookies: a
                            persistent cookie will be stored by a web browser and will remain valid until its set expiry date,
                            unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire
                            at the end of the user session, when the web browser is closed.
                        </li>
                        <li>
                            Cookies do not typically contain any information that personally
                            identifies a user, but personal information that we store about you may be linked to the information
                            stored in and obtained from cookies.
                        </li>
                    </ol>
                </li>
                <li class="section ">
                    Cookies that we use
                    <ol class="mb-3 ">
                        <li>
                            We use cookies for the following purposes:
                            <ol class="mb-3">
                                <li>
                                    authentication - we use cookies to identify you when you visit our website and as
                                    you navigate our website (cookies used for this purpose are: email);
                                </li>
                                <li>
                                    status - we use cookies to help us to determine if you are logged into our website
                                    (cookies used for this purpose are: token);
                                </li>
                                <li>
                                    security - we use cookies as an element of the security measures used to protect
                                    user accounts, including preventing fraudulent use of login credentials, and to protect our
                                    website and services generally (cookies used for this purpose are: correlation);
                                </li>
                                <li>
                                    cookie consent - we use cookies to store your preferences in relation to the use of
                                    cookies more generally (cookies used for this purpose are: cookies_asked).
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li class="section ">
                    Cookies used by our service providers
                    <ol class="mb-3 ">
                        <li>
                            Our service providers use cookies and those cookies may be stored on your
                            computer when you visit our website.
                        </li>
                        <li>
                            We use YouTube to embed videos directly into our web pages.
                            YouTube use multiple cookies to collect or receive information about you when you play an embedded YouTube video on our website.
                            YouTube is a service provided by Google and their privacy policy is available at: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
                        </li>
                        <li>
                            We use Spotify to embed music playlists directly into our web pages.
                            Spotify use multiple cookies to collect or receive information about you when you view an embedded Spotify playlist on our website.
                            Spotify's privacy policy is available at: <a href="https://www.spotify.com/uk/legal/privacy-policy/">https://www.spotify.com/uk/legal/privacy-policy/</a>.
                        </li>
                        <li>
                            We use Soundslice to embed music tuition players directly into our web pages.
                            Soundslice use multiple cookies to collect or receive information about you when you view an embedded Soundslice player on our website.
                            Soundslice's privacy policy is available at: <a href="https://www.soundslice.com/terms/">https://www.soundslice.com/terms/</a>.
                        </li>
                        <li>
                            We use Google Analytics to analyse the use of our website. Google
                            Analytics gathers information about website use by means of cookies. The information gathered
                            relating to our website is used to create reports about the use of our website. Google's privacy
                            policy is available at: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
                        </li>
                        <li>
                            We use Facebook to serve your relevant adverts from our Facebook Business Page.
                            Facebook use the Facebook Pixel to collect or receive information about your browsing habits and use that information to provide measurement services,
                            and targeted adverts, as described in the <a href="https://www.facebook.com/about/privacy/" target="_blank">Facebook Data Policy</a>.
                            You can opt-out of the collection and use of information for advert targeting across all the websites you visit here
                            - <a href="http://www.aboutads.info/choices" target="_blank">http://www.aboutads.info/choices</a>.
                        </li>
                        <li>
                            We use Google AdWords to serve you relevant adverts while you are using Google's services.
                            Google AdWords use cookies to collect or receive information about your browsing habits and use that information to provide measurement services,
                            and targeted adverts, as described in their privacy policy - <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
                            You can opt-out of the collection and use of information for advert targeting across all the websites you visit here
                            - <a href="http://www.aboutads.info/choices" target="_blank">http://www.aboutads.info/choices</a>.
                        </li>
                        <li>
                            We use Google Ads to serve you relevant adverts while you are using Google's services.
                            Google uses cookies to collect or receive information about your browsing habits and use that information to provide measurement services,
                            and targeted adverts, as described in their privacy policy - <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
                            You can opt-out of the collection and use of information for advert targeting across all the websites you visit here
                            - <a href="http://www.aboutads.info/choices" target="_blank">http://www.aboutads.info/choices</a>.
                        </li>
                        <li>
                            We use Stripe to process payments in our online shop.
                            Stripe use necessary cookies to identify you in order to provide a secure payment gateway, and streamline your checkouts,
                            as described in their privacy policy - <a href="https://stripe.com/gb/privacy">https://stripe.com/gb/privacy</a>.
                        </li>
                    </ol>
                </li>
                <li class="section ">
                    Managing cookies
                    <ol class="mb-3 ">
                        <li>
                            Most browsers allow you to refuse to accept cookies and to delete cookies.
                            The methods for doing so vary from browser to browser, and from version to version. You can however
                            obtain up-to-date information about blocking and deleting cookies via these links:
                            <ol>
                                <li>
                                    <a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>
                                    (Chrome)
                                </li>
                                <li>
                                    <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>
                                    (Firefox)
                                </li>
                                <li>
                                    <a href="http://www.opera.com/help/tutorials/security/cookies/">http://www.opera.com/help/tutorials/security/cookies/</a>
                                    (Opera)
                                </li>
                                <li>
                                    <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                                    (Internet Explorer)
                                </li>
                                <li>
                                    <a href="https://support.apple.com/kb/PH21411">https://support.apple.com/kb/PH21411</a>
                                    (Safari)
                                </li>
                                <li>
                                    <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a>
                                    (Edge)
                                </li>
                            </ol>
                        </li>
                        <li>
                            Blocking all cookies will have a negative impact upon the usability of
                            many websites.
                        </li>
                        <li>
                            If you block cookies, you will not be able to use all the features on our
                            website.
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Cookie preferences
                    <ol class="mb-3 ">
                        <li>
                            You can manage your preferences relating to the use of cookies on our
                            website by visiting:
                            <a :href="$config.public.url + 'cookies/'">{{ $config.public.url + 'cookies/' }}</a>
                        </li>
                    </ol>
                </li>
                <li class="section  mb-3">
                    Our details
                    <ol>
                        <li>This website is owned and operated by {{ $config.public.companyNameLong }}.</li>
                        <li>
                            We are registered in {{ $config.public.companyRegisteredIn }} under registration
                            number {{ $config.public.companyRegisteredNumber }}, and our registered office is at {{ $config.public.companyAddress }}.
                        </li>
                        <li>
                            You can contact us:
                            <ol>
                                <li>
                                    by post, to {{ $config.public.companyAddress }};
                                </li>
                                <li>
                                    by email, using {{ $config.public.companyEmail }};
                                </li>
                                <li>
                                    using our website contact form.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
        </section>
    </div>
</template>

<script>
export default {
    name: 'ArticlePrivacyPolicy',
    computed: {
        policies () {
            return [
                {
                    id: '0-0',
                    subject: 'Website Account Holder',
                    group: 'User Account',
                    group_description: 'the information you provide to create or edit your user account, which may include name, email, telephone, profile picture, profile description, occupation, company name and your interest in the website. Your account data may be shared with our staff to assist with account or service enquiries. Personal data may also be processed in the delivery of our private messaging system.',
                    expiry_minutes: null,
                    legal_basis: 'Consent',
                    legal_basis_description: 'A Data Subject has given clear consent for a Data Controller to process their personal data for a specific purpose.',
                    expiry_readable: 'will be retained until the user withdraws consent'
                },
                {
                    id: '0-1',
                    subject: 'Website Account Holder',
                    group: 'Live Stream Comments',
                    group_description: 'the information you provide when adding a comment to a live stream. A live stream comment is displayed publicly on the internet with your first name, and your profile picture if you have uploaded one.',
                    expiry_minutes: null,
                    legal_basis: 'Consent',
                    legal_basis_description: 'A Data Subject has given clear consent for a Data Controller to process their personal data for a specific purpose.',
                    expiry_readable: 'will be retained until the user withdraws consent'
                },
                {
                    id: '0-2',
                    subject: 'Website Account Holder',
                    group: 'Lesson Comments',
                    group_description: 'the information you provide when adding a comment to a lesson. A lesson comment is displayed publicly on the internet with your first name, and your profile picture if you have uploaded one.',
                    expiry_minutes: null,
                    legal_basis: 'Consent',
                    legal_basis_description: 'A Data Subject has given clear consent for a Data Controller to process their personal data for a specific purpose.',
                    expiry_readable: 'will be retained until the user withdraws consent'
                },
                {
                    id: '0-3',
                    subject: 'Website Account Holder',
                    group: 'Blog Posts',
                    group_description: 'the information you provide when adding a blog post to the website. A published blog post is displayed publicly on the internet with your first name, profile description, and your profile picture if you have uploaded one.',
                    expiry_minutes: null,
                    legal_basis: 'Consent',
                    legal_basis_description: 'A Data Subject has given clear consent for a Data Controller to process their personal data for a specific purpose.',
                    expiry_readable: 'will be retained until the user withdraws consent'
                },
                {
                    id: '0-4',
                    subject: 'Website Account Holder',
                    group: 'Blog Post Comments',
                    group_description: 'the information you provide when adding a comment to a blog post. A blog post comment is displayed publicly on the internet with your first name, and your profile picture if you have uploaded one.',
                    expiry_minutes: null,
                    legal_basis: 'Consent',
                    legal_basis_description: 'A Data Subject has given clear consent for a Data Controller to process their personal data for a specific purpose.',
                    expiry_readable: 'will be retained until the user withdraws consent'
                },
                {
                    id: '0-5',
                    subject: 'Website Account Holder',
                    group: 'Website Sessions',
                    group_description: 'technical data about users logged in to the website including IP address, device information, and a last access date. This personal data is processed to allow users to login to the website and close inactive sessions.',
                    expiry_minutes: 43800,
                    legal_basis: 'Legitimate Interest',
                    legal_basis_description: 'The processing is necessary for the legitimate interests of a Data Controller or the legitimate interests of a third party unless there is a good reason to protect a Data Subject’s personal data which overrides those legitimate interests.',
                    expiry_readable: 'will be retained for 1 month'
                },
                {
                    id: '0-6',
                    subject: 'Website Account Holder',
                    group: 'Website Logs',
                    group_description: 'technical data about use of the website including IP address, pages served, actions taken and error messages. This personal data is processed to reduce errors, develop the user experience and improve security of personal data.',
                    expiry_minutes: 1051200,
                    legal_basis: 'Legitimate Interest',
                    legal_basis_description: 'The processing is necessary for the legitimate interests of a Data Controller or the legitimate interests of a third party unless there is a good reason to protect a Data Subject’s personal data which overrides those legitimate interests.',
                    expiry_readable: 'will be retained for 2 years'
                },
                {
                    id: '0-7',
                    subject: 'Website Account Holder',
                    group: 'System Emails',
                    group_description: 'technical data about emails sent by the website to account holders, which is processed to maintain a record of system generated communication with users to reduce errors and improve user experience. This data is never shared with a third party.',
                    expiry_minutes: 131400,
                    legal_basis: 'Legitimate Interest',
                    legal_basis_description: 'The processing is necessary for the legitimate interests of a Data Controller or the legitimate interests of a third party unless there is a good reason to protect a Data Subject’s personal data which overrides those legitimate interests.',
                    expiry_readable: 'will be retained for 3 months'
                },
                {
                    id: '1-0',
                    subject: 'Website Visitor',
                    group: 'Website Logs',
                    group_description: 'technical data about use of the website including IP address, pages served, actions taken and error messages. This personal data is processed to reduce errors, develop the user experience and improve security of personal data.',
                    expiry_minutes: 525600,
                    legal_basis: 'Legitimate Interest',
                    legal_basis_description: 'The processing is necessary for the legitimate interests of a Data Controller or the legitimate interests of a third party unless there is a good reason to protect a Data Subject’s personal data which overrides those legitimate interests.',
                    expiry_readable: 'will be retained for 1 year'
                },
            ];
        },
    },
};
</script>
