export default function () {

    function build(entity) {
        if (!entity) throw Error('No supplied entity.');
        return {
            ...entity,
            full_name: fullName (entity),
            profile_letter: profileLetter (entity),
            message_created_time_ago: messageCreatedTimeAgo (entity),
        };
    }

    const UtilityDates = useUtilityDates();
    const UtilityFunctions = useUtilityFunctions();

    function fullName (entity) {
        if (!entity.first_name || !entity.last_name) return undefined;
        return UtilityFunctions.capitalizeFirstLetter(entity.first_name) + ' ' + UtilityFunctions.capitalizeFirstLetter(entity.last_name);
    }

    function profileLetter (entity) {
        if (!entity.first_name) return undefined;
        return entity.first_name.charAt(0).toUpperCase();
    }

    function messageCreatedTimeAgo (entity) {
        if (!entity.message_created) return undefined;
        return UtilityDates.printDateTimeAgo(entity.message_created);
    }

    return {build};
}
