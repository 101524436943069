// Be sure to declare this outside the exported function to create singleton
let token = null;

export default function () {

    token = useCookieToken().get() || null;

    function get () {
        return token;
    }

    function set (value) {
        token = value;
        useCookieToken().set(value);
    }

    function remove () {
        token = null;
        useCookieToken().remove();
    }

    return {get, set, remove};
}
