export default function () {

    async function get (idSubscription, filter) {
        let invoices = null, error = null;
        try {
            const {data} = await useApi('finance').get('me/subscriptions/' + idSubscription + '/invoices', filter);
            invoices = data.invoices;
        } catch (e) {
            error = useApiError(e);
        }
        return {invoices, error};
    }

    return {get};
}
