<template>
    <div class="article-answer-app-download">
        <p>
            Download the Andy Guitar app free on the <a
                href="https://play.google.com/store/apps/details?id=uk.co.andyguitar">Google Play store here.</a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerAppDownload',
};
</script>
