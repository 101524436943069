function removeTrailingSlash (str) {
    if (str.endsWith('/')) {
        return str.slice(0, -1);
    } else {
        return str;
    }
}

const redirectedProductSlugs = [
    {website_slug: 'andys-signature-ukulele-tuner-bag-bundle-10-off', shopify_slug: 'andys-signature-ukulele-tuner-bag-bundle'},
    {website_slug: 'brunswick-agf200-acoustic-guitar-10-off', shopify_slug: 'andys-signature-acoustic-guitar-brunswick-agf200'},
    {website_slug: 'andys-signature-ukulele-brunswick-agcu200', shopify_slug: 'andys-signature-ukulele-tuner-bag-bundle'},
    {website_slug: 'christmas-songbook', shopify_slug: 'christmas-songbook'},
    {website_slug: 'first-50-blues-songs-you-should-play-on-guitar', shopify_slug: 'first-50-blues-songs-you-should-play-on-guitar'},
    {website_slug: 'andy-guitar-capo', shopify_slug: 'andy-guitar-signature-capo'},
    {website_slug: 'brunswick-agf200-acoustic-guitar', shopify_slug: 'andys-signature-acoustic-guitar-brunswick-agf200'},
    {website_slug: 'simple-acoustic-songs-the-easiest-easy-guitar-songbook-ever', shopify_slug: 'simple-acoustic-songs-the-easiest-easy-guitar-songbook-ever'},
    {website_slug: 'andys-recommended-bass-songbook', shopify_slug: 'andys-recommended-bass-songbook'},
    {website_slug: 'andys-recommended-ukulele-songbook', shopify_slug: 'andys-recommended-ukulele-songbook'},
    {website_slug: 'the-complete-guitar-player-acoustic-songbook', shopify_slug: 'the-complete-guitar-player-acoustic-songbook'},
    {website_slug: 'the-complete-guitar-player-rock-songbook', shopify_slug: 'the-complete-guitar-player-rock-songbook'},
    {website_slug: 'andys-victory-amplifier', shopify_slug: 'andys-victory-amplifier'},
    {website_slug: 'andys-acoustic-guitars', shopify_slug: 'andys-acoustic-guitar-recommendations'},
    {website_slug: 'andys-main-electric-guitars', shopify_slug: 'andys-electric-guitar-recommendations'},
    {website_slug: 'acoustic-guitar-strings', shopify_slug: 'acoustic-guitar-strings'},
    {website_slug: 'electric-guitar-strings', shopify_slug: 'electric-guitar-strings'},
    {website_slug: 'andys-fingerstyle-course-dvd', shopify_slug: 'andys-fingerstyle-course-dvd'},
    {website_slug: 'electric-rock-lead-guitar-dvd', shopify_slug: 'electric-rock-lead-guitar-dvd'},
    {website_slug: 'andy-guitar-picks-pearl-finish', shopify_slug: 'andy-guitar-picks-pearl-finish'},
    {website_slug: 'andys-easy-fingerstyle-songbook-for-beginners', shopify_slug: 'andys-easy-fingerstyle-songbook-for-beginners'},
    {website_slug: 'electric-rock-lead-guitar-coursebook', shopify_slug: 'electric-rock-lead-guitar-course-book'},
    {website_slug: 'andys-fingerstyle-coursebook-and-songbook', shopify_slug: 'andys-fingerstyle-course-book'},
    {website_slug: 'electric-guitar-recommendation', shopify_slug: 'andys-electric-guitar-recommendations'},
    {website_slug: 'andys-budget-amplifier-recomendation', shopify_slug: 'andys-budget-amplifier-recommendations'},
    {website_slug: 'andys-acoustic-guitar-recommendation', shopify_slug: 'andys-acoustic-guitar-recommendations'},
    {website_slug: 'andys-budget-capo-recommendation', shopify_slug: 'andy-guitar-signature-capo'},
    {website_slug: 'andys-capo-recommendation', shopify_slug: 'andy-guitar-signature-capo'},
    {website_slug: 'andys-recommended-plectrums', shopify_slug: 'andy-guitar-picks'},
    {website_slug: 'more-simple-songs-the-easiest-easy-guitar-songbook-ever', shopify_slug: 'more-simple-songs-the-easiest-easy-guitar-songbook-ever'},
    {website_slug: 'simple-songs-the-easiest-easy-guitar-songbook-ever', shopify_slug: 'simple-songs-the-easiest-easy-guitar-songbook-ever'},
    {website_slug: 'first-50-songs-you-should-fingerpick-on-guitar', shopify_slug: 'first-50-songs-you-should-fingerpick-on-guitar'},
    {website_slug: 'first-50-rock-songs-you-should-play-on-electric-guitar', shopify_slug: 'first-50-rock-songs-you-should-play-on-electric-guitar'},
    {website_slug: 'first-50-songs-you-should-play-on-acoustic-guitar', shopify_slug: 'first-50-songs-you-should-play-on-acoustic-guitar'},
    {website_slug: 'first-50-songs-you-should-strum-on-guitar', shopify_slug: 'first-50-songs-you-should-strum-on-guitar'},
    {website_slug: 'andys-music', shopify_slug: 'andys-debut-album'},
    {website_slug: 'andys-guitar-picks', shopify_slug: 'andy-guitar-picks'},
    {website_slug: 'andys-improver-guitar-course-dvd', shopify_slug: 'andys-improver-course-dvd'},
    {website_slug: 'andys-improver-course-book', shopify_slug: 'andys-improver-course-book'},
    {website_slug: 'andys-strumming-course-book', shopify_slug: 'andys-strumming-course-book'},
    {website_slug: 'andys-strumming-course-dvds', shopify_slug: 'andys-strumming-course-dvd'},
    {website_slug: 'andys-beginners-course-book', shopify_slug: 'andys-beginners-course-book'},
    {website_slug: 'andys-beginner-course-dvds', shopify_slug: 'andys-beginners-course-dvd'},

    // With SKU
    {website_slug: 'andys-guitar-picks/andys-guitar-picks', shopify_slug: 'andy-guitar-picks?variant=44943362982170'},
    {website_slug: 'andys-guitar-picks/5-andy-guitar-picks-71mm-medium', shopify_slug: 'andy-guitar-picks?variant=44943363014938'},
    {website_slug: 'andys-guitar-picks/5-andy-guitar-picks-1mm-heavy', shopify_slug: 'andy-guitar-picks?variant=44943363047706'},
    {website_slug: 'andy-guitar-picks-pearl-finish/pack-of-5-andy-guitar-picks-pearl-finish-071mm-medium', shopify_slug: 'andy-guitar-picks-pearl-finish?variant=46042035323162'},
    {website_slug: 'andy-guitar-picks-pearl-finish/pack-of-5-andy-guitar-picks-pearl-finish-1mm-heavy', shopify_slug: 'andy-guitar-picks-pearl-finish?variant=46042035355930'},
];

const redirectedShopTagSlugs = [
    {website_slug: 'dvds', shopify_slug: 'dvds'},
    {website_slug: 'songbooks', shopify_slug: 'songbooks'},
    {website_slug: 'guitars', shopify_slug: 'signature-collection'},
    {website_slug: 'music', shopify_slug: 'music'},
    {website_slug: 'books', shopify_slug: 'books'},
    {website_slug: 'offers', shopify_slug: ''},
    {website_slug: 't-shirts', shopify_slug: ''},
    {website_slug: 'andy-guitar-capo', shopify_slug: 'signature-collection'},
    {website_slug: 'brunswick-agf200-acoustic-guitar', shopify_slug: 'signature-collection'},
    {website_slug: 'andys-new-signature-ukulele', shopify_slug: 'signature-collection'},
    {website_slug: 'andy-guitar-signature-collection', shopify_slug: 'signature-collection'}
];

const shopifyUrl = 'https://shop.andyguitar.co.uk';

const redirectToShopify = function (shopifySlug) {
    navigateTo(`${shopifyUrl}${shopifySlug}`, {external: true});
    return abortNavigation('Redirected'); // Prevents 404 page from flashing
};

export default function redirectShopifyRoutes (to) {

    // Check it begins with /shop
    const match = to.path.match(/^\/shop($|\/.*)/);
    if (!match) return;

    let slug = match[0].slice(5); // remove preceding /shop

    // Miscellaneous redirects to shop home page
    if (removeTrailingSlash(slug) === '') return redirectToShopify('');
    if (removeTrailingSlash(slug) === '/cart') return redirectToShopify('/');
    if (removeTrailingSlash(slug) === '/checkout') return redirectToShopify('/');
    if (slug.startsWith('/payment')) return redirectToShopify('/');

    // Entity index pages
    if (removeTrailingSlash(slug) === '/products') return redirectToShopify('/collections/all');
    if (removeTrailingSlash(slug) === '/tags') return redirectToShopify('/collections');

    // Handle product redirects
    if (slug.startsWith('/products/')) {
        const productSlug = removeTrailingSlash(slug.slice(10));
        const matchedShopifyRedirect = redirectedProductSlugs.find(slug => slug.website_slug === productSlug);
        if (matchedShopifyRedirect) return redirectToShopify(`/products/${matchedShopifyRedirect.shopify_slug}`);
        return redirectToShopify('/products'); // Catch all to products index page
    }

    // Handle product tag (Shopify collections) redirects
    if (slug.startsWith('/tags/')) {
        const tagSlug = removeTrailingSlash(slug.slice(6));
        const matchedShopifyRedirect = redirectedShopTagSlugs.find(slug => slug.website_slug === tagSlug);
        if (matchedShopifyRedirect) return redirectToShopify(`/collections/${matchedShopifyRedirect.shopify_slug}`);
        return redirectToShopify('/collections'); // Catch all to collections index page
    }

    // Handle product tags without preceding /tag in slug
    const tagSlug = removeTrailingSlash(slug);
    const matchedShopifyRedirect = redirectedShopTagSlugs.find(slug => '/' + slug.website_slug === tagSlug);
    if (matchedShopifyRedirect) return redirectToShopify(`/collections/${matchedShopifyRedirect.shopify_slug}`);

    // Fallback redirect to shop
    return redirectToShopify('/');
}
