export default function () {

    function build (entity) {
        if (!entity) throw Error('No supplied entity.');
        return {
            ...entity,
            thread_title: threadTitle(entity),
            profile_letter: profileLetter(entity),
            is_unread: isUnread(entity),
            thread_edited_time_ago: threadEditedTimeAgo(entity),
        };
    }

    async function post (params) {
        let thread = null, error = null;
        try {
            const {data} = await useApi('messages').post('helpdesks', params);
            thread = build(data.thread);
        } catch (e) {
            error = useApiError(e);
        }
        return {thread, error};
    }

    async function get (idThread, token) {
        let thread = null, error = null;
        let endpoint = token ? 'threads/' + idThread + '/' + token : 'threads/' + idThread;
        try {
            const {data} = await useApi('messages').get(endpoint);
            thread = build(data.thread);
        } catch (e) {
            error = useApiError(e);
        }
        return {thread, error};
    }

    async function read (idThread, token) {
        let error = null;
        let endpoint = token ? 'threads/' + idThread + '/' + token + '/read' : 'threads/' + idThread + '/read';
        try {
            await useApi('messages').put(endpoint);
        } catch (e) {
            error = useApiError(e);
        }
        return {error};
    }

    async function message (idThread, content, token) {
        let message = null, error = null;
        let endpoint = token ? 'threads/' + idThread + '/' + token + '/messages' : 'threads/' + idThread + '/messages';
        let params = {
            message_content: content
        };
        try {
            const {data} = await useApi('messages').post(endpoint, params);
            message = build(data.message);
        } catch (e) {
            error = useApiError(e);
        }
        return {message, error};
    }

    const UtilityFunctions = useUtilityFunctions();
    const UtilityDates = useUtilityDates();

    function threadTitle (entity) {
        if (entity.thread_title) return entity.thread_title;
        if (entity.private_thread && entity.first_name_other && entity.last_name_other) {
            return UtilityFunctions.capitalizeFirstLetter(entity.first_name_other) + ' ' + UtilityFunctions.capitalizeFirstLetter(entity.last_name_other);
        }
        return 'Group Message';
    }

    function profileLetter (entity) {
        if (entity.private_thread) {
            return entity.first_name_other ? entity.first_name_other.charAt(0).toUpperCase() : threadTitle(entity).charAt(0).toUpperCase();
        }
        return entity.first_name_last ? entity.first_name_last.charAt(0).toUpperCase() : threadTitle(entity).charAt(0).toUpperCase();
    }

    function isUnread (entity) {
        return entity.thread_edited > entity.last_read_timestamp;
    }

    function threadEditedTimeAgo (entity) {
        return UtilityDates.printDateTimeAgo(entity.thread_edited);
    }

    return {build, post, get, read, message};
}
