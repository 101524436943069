export default function () {
    const EntityLesson = useEntityLesson();

    async function get (filter) {
        let viewed = null, error = null;
        try {
            const {data} = await useApi('onboard').get('viewed', filter);
            viewed = data.viewed.map((lesson) => {
                return EntityLesson.build(lesson);
            });
        } catch (e) {
            error = useApiError(e);
        }
        return {viewed, error};
    }

    return {get};
}
