export default function () {

    const year = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);
    const cookie = useCookie('email', {expires: year, secure: true, path: '/'});

    function get () {
        return cookie.value || null;
    }

    function set (email) {
        cookie.value = email || '';
    }

    function remove () {
        cookie.value = null;
    }

    return {get, set, remove};
};
