<template>
    <div class="article-answer-download-tracks">
        <p>
            You can download audio tracks from the website audio player directly, just click the three dots on the right hand side of the
            audio player.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerDownloadTracks',
};
</script>
