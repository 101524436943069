export default function () {

    const EntitySubscription = useEntitySubscription();
    const ApiFinance = useApi('finance');

    async function get (filter) {
        let subscriptions = null, error = null;
        try {
            const {data} = await ApiFinance.get('me/subscriptions', filter);
            subscriptions = data.subscriptions.map((subscription) => {
                return EntitySubscription.build(subscription);
            });
        } catch (e) {
            error = useApiError(e);
        }
        return {subscriptions, error};
    }

    return {get};
}
