<template>
    <section class="section-text" :class="color">
        <LayoutContained :class="padding">
            <ContentHeading
                v-if="heading"
                :heading="heading"
                :headingColor="headingColor"
                :headingComponent="headingComponent"
                :marginBottom="content ? 'mb-6' : 'mb-0'"
                :textCentered="textCentered"
            ></ContentHeading>
            <ContentText
                v-if="content"
                :html="content"
                :contentColor="contentColor"
                :textCentered="textCentered"
            ></ContentText>
            <div v-if="buttonText" class="mt-8" :class="!!textCentered ? 'text-center' : ''">
                <ButtonPrimary
                    :buttonColor="buttonColor || undefined"
                    :buttonRoute="buttonRoute"
                    :buttonText="buttonText"
                    :buttonIcon="buttonIcon"
                    :buttonClick="buttonClick"
                    :buttonClickParams="buttonClickParams"
                    :buttonHref="buttonHref"
                ></ButtonPrimary>
            </div>
            <slot name="custom"></slot>
        </LayoutContained>
    </section>
</template>

<script>
export default {
    name: 'SectionText',
    props: {
        padding: {
            required: false,
            default: 'py-12 sm:py-14',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonHref: {
            required: false,
            type: String,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonIcon: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-gray-600',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
};
</script>
