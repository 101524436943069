const twoDays = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);

export default function () {

    const cookie = useCookie('return', {expires: twoDays, path: '/'});

    function get () {
        return cookie.value || null;
    }

    function set (route) {
        cookie.value = JSON.stringify(route);
    }

    function remove () {
        cookie.value = null;
    }

    return {get, set, remove};
};
