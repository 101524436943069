<template>
    <div class="article-answer-chord-sheets">
        <p>
            We cannot display chord sheets or TAB for copyrighted songs directly on this website/app or offer them for
            download as this would break copyright law.
            <NuxtLink to="/membership">The AAA membership</NuxtLink>
            does not include any chord sheets or TAB for commercial songs.
        </p>
        <p>
            Where possible, we link to external websites that display chord sheets or TAB for copyrighted
            songs for free. If available, these links are found by clicking the Chord Sheets button below the video.
        </p>
        <p>
            We try to keep chord sheet links up to date as best we can. However, as they are on external
            sites these can change or be deleted at any time without our knowledge.
        </p>
        <p>
            Printable chord sheets that are officially arranged by Andy <a
                href="https://www.sheetmusicplus.com/en/explore?cgid=6389">are available here.</a>
        </p>
        <p>
            For recommended paperback songbooks, <a
                href="https://shop.andyguitar.co.uk/collections/songbooks">see Andy's recommended songbooks here.</a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerChordSheets',
};
</script>
