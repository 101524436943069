export const useStoreCourse = defineStore('StoreCourse', {
    state: () => ({
        course: null,
        error: null,
    }),
    actions: {
        async getCourse (courseSlug) {
            this.error = null;
            let is404 = null;
            if (this.course && this.course.course_slug !== courseSlug) {
                this.course = null;
            }
            ({course: this.course, error: this.error, is404} = await useEntityCourse().get(courseSlug));
            if (is404) {
                const event = useRequestEvent();
                setResponseStatus(event, 404);
            }
        },
        reset: function () {
            this.course = null;
            this.error = null;
        },
    },
    getters: {
        firstLesson () {
            if (!this.course) return null;
            let lessons = [];
            this.course.course_lessons.forEach(lesson => {
                lessons.push(lesson);
            });
            return lessons[0];
        },
        resumeLesson () {
            if (!this.course?.id_course) return null;
            return useStoreContinue().getCourseProgress(this.course.id_course);
        },
    },
});
