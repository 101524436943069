export default defineNuxtPlugin(nuxt => {
    const routerUtilities = {};
    const $router = useRouter();
    const $config = useRuntimeConfig().public;

    /**
     * Returns true if a passed-in URL matches the hostname the app is configured to be hosted from
     * @param url
     * @returns {any|boolean}
     */
    function hostnameMatch (url) {
        const ConfigUrl = new URL($config.url);
        let testUrl;
        try {
            testUrl = new URL(url);
        } catch (e) {
            return url.startsWith('/');
        }
        return ConfigUrl.hostname === testUrl.hostname;
    }

    /**
     * Takes in a URL string and returns a normalised version of it
     * Adding scheme and hostname if not added
     * @param url
     * @returns {string}
     */
    routerUtilities.normalizeUrl = function (url) {
        let Url;
        try {
            Url = new URL(url);
        } catch (e) {
            if (url.startsWith('/')) {
                Url = new URL(`${$config.url}${url.substring(1)}`);
            } else {
                Url = new URL(`https://${url}`);
            }
        }
        return Url.href;
    };

    /**
     * Queries the router and returns an object with details of any matched routes in the router
     * @param url
     * @returns {{matched: boolean, href: string}|{route: *, matched: boolean, href}}
     */
    routerUtilities.matchRoute = function (url) {
        url = routerUtilities.normalizeUrl(url);
        if (!hostnameMatch(url)) {
            return {
                matched: false,
                href: url,
            };
        }
        const Url = new URL(url);
        const route = $router.resolve(`${Url.pathname}${Url.search}${Url.hash}`);
        if (!route.name) {
            return {
                matched: false,
                href: url,
            };
        }
        return {
            matched: true,
            href: route.href,
            route: route,
        };
    };

    routerUtilities.getRouteFromUrl = function (url) {
        try {
            const nuxtRoute = routerUtilities.matchRoute(url);
            return nuxtRoute && nuxtRoute.route && nuxtRoute.route.href;
        } catch (e) {
            return url;
        }
    };

    routerUtilities.replaceLinksWithNuxtRoutes = function (html) {
        if (import.meta.server) {
            console.warn('Warning! Tried to run routerUtilities.replaceLinksWithNuxtRoutes on the server!');
            return html;
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Loop through all links and buttons and replace them as appropriate
        const oldElements = Array.from(doc.querySelectorAll('a, button, v-btn'));

        for (let i = 0; i < oldElements.length; i++) {
            const oldElement = oldElements[i];
            // Set the href or to attribute, depending on the type of link
            const href = oldElement.getAttribute('href');
            const route = routerUtilities.matchRoute(href);

            let newTag;
            if (!route.matched) {
                if (oldElement.tagName === 'A') {
                    newTag = doc.createElement('a');
                    newTag.setAttribute('href', route.href);
                    newTag.setAttribute('target', '_blank');
                } else {
                    newTag = doc.createElement('v-btn');
                    newTag.setAttribute('target', '_blank');
                    newTag.setAttribute('href', route.href);
                }
            } else if (route.matched) {
                if (oldElement.tagName === 'A') {
                    newTag = doc.createElement('router-link');
                    newTag.setAttribute('to', route.route.href);
                } else {
                    newTag = doc.createElement('v-btn');
                    newTag.setAttribute('to', route.route.href);
                    newTag.classList.add('text-white');
                }
            }

            // Clone the existing tag, all its attributes, and inner HTML
            for (let j = 0; j < oldElement.attributes.length; j++) {
                if (oldElement.attributes[j].name === 'href') continue; // Ignore href attribute since we've already handled this above
                const attr = oldElement.attributes[j];
                newTag.setAttribute(attr.nodeName, attr.nodeValue);
            }

            const clonedAnchorTag = oldElement.cloneNode(true);
            newTag.innerHTML = clonedAnchorTag.innerHTML;

            // Replace the old element from the DOM
            oldElement.parentNode.replaceChild(newTag, oldElement);
        }

        return doc.body.innerHTML;
    };

    nuxt.provide('routerUtilities', routerUtilities);
});
