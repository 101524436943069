export default function () {

    async function get (idSubscription, filter) {
        let paymentMethods = null, error = null;
        try {
            const {data} = await useApi('finance').get('me/subscriptions/' + idSubscription + '/methods', filter);
            paymentMethods = data.payment_methods;
        } catch (e) {
            error = useApiError(e);
        }
        return {paymentMethods, error};
    }

    return {get};
}
