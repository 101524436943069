// eslint-disable-next-line no-unused-vars
export default defineNuxtRouteMiddleware(async (to, from) => {

    // Misc old routes to send to homepage
    if (to.path === '/alerts') return redirect('/');
    if (to.path === '/learn-more') return redirect('/');
    if (to.path === '/notifications') return redirect('/');

    // Contact
    if (to.path === '/contact') return redirect('/helpdesk');

    // Various routes replaced with videos search
    if (to.path === '/lessons') return redirect('/videos');
    if (to.path === '/lessons/meta') return redirect('/videos');
    if (to.path === '/online-lessons') return redirect('/videos');
    if (to.path === '/online-lessons/lessons') return redirect('/videos');
    if (to.path === '/online-lessons/lessons-search') return redirect('/videos');
    if (to.path === '/online-lessons/meta') return redirect('/videos');
    if (to.path === '/online-lessons/tags') return redirect('/videos');
    if (to.path === '/songs') return redirect('/videos');
    if (to.path === '/videos/meta') return redirect('/videos');

    // Various routes replaced with courses page
    if (to.path === '/online-lessons/courses') return redirect('/courses');
    if (to.path === '/online-lessons/learning-pathways') return redirect('/courses');

    // Dashboard no longer exists
    if (to.path.startsWith('/dashboard'))
    {
        // Subscriptions
        if (to.path.startsWith('/dashboard/subscriptions')) {
            return movedRoute('/dashboard/subscriptions', '/me/subscriptions');
        }
        if (to.path.startsWith('/guest/subscriptions')) {
            return movedRoute('/guest/subscriptions', '/me/subscriptions');
        }

        // Membership
        if (to.path === '/dashboard/member-status') return redirect('/membership');
        if (to.path === '/member-status') return redirect('/membership');
        if (to.path === '/shop/access-all-areas') return redirect('/membership');
        if (to.path === '/shop/downloads') return redirect('/membership');
        if (to.path.startsWith('shop/subscribe')) return redirect('/membership');

        // Welcome
        if (to.path === '/dashboard/welcome') {
            return redirect('/welcome');
        }

        // My list
        if (to.path === '/dashboard/my-list' || to.path.startsWith('/dashboard/bookmarks')) {
            return redirect('/my-list');
        }

        // Free resources
        if (to.path === '/dashboard/free-resources') {
            return redirect('/free-resources');
        }

        // Library
        if (to.path === '/dashboard/library') {
            return redirect('/courses');
        }

        // Songs
        if (to.path === '/dashboard/songs') {
            return redirect('/videos');
        }

        // Redirect all other matching dashboard routes to the home page
        return redirect('/');
    }

    if (to.path.startsWith('/settings'))
    {
        return redirect('/me/edit');
    }

    // Past live streams are now videos
    if (to.path.startsWith('/live-streams')) {
        return redirect('/live');
    }

    // These routes no longer exist, served by courses pages itself
    if (to.path === '/courses/list' || to.path.startsWith('/courses/pathways') || to.path.startsWith('/online-lessons/learning-pathways')) {
        return redirect('/courses');
    }

    // Courses with changed slugs
    if (to.params.courseSlug) {
        const redirectedCourses = [
            {oldSlug: 'beginner-course', newSlug: 'absolute-beginner-guitar'},
            {oldSlug: 'electric-rock-rhythm', newSlug: 'electric-guitar-level-2-rock-rhythm-lessons'},
            {oldSlug: 'intermediate-guitar-course-acoustic-and-electric', newSlug: 'intermediate-free-taster-module'},
            {oldSlug: 'lead-guitar-1', newSlug: 'lead-guitar-level-1-techniques'},
            {oldSlug: 'lead-guitar-2', newSlug: 'lead-guitar-level-7-minor-pentatonic-runs'},
            {oldSlug: 'lead-guitar-3', newSlug: 'lead-guitar-level-13-5-minor-pentatonic-shapes-in-a'},
            {oldSlug: 'learn-ukulele-with-andy', newSlug: 'ukulele-introduction'},
        ];

        const redirectedCourse = redirectedCourses.find(redirectedCourse => redirectedCourse.oldSlug === to.params.courseSlug);

        if (redirectedCourse) {
            const newRoute = {
                name: to.name,
                params: to.params,
                query: to.query,
            };
            newRoute.params.courseSlug = redirectedCourse.newSlug;
            return redirect(newRoute);
        }
    }

    function redirect (route) {
        return navigateTo(route, {
            statusCode: 302,
            replace: true
        });
    }

    function movedRoute (oldPrefix, newPrefix) {
        return redirect({
            path: to.path.replace(oldPrefix, newPrefix),
            query: to.query,
        });
    }
});
